export const LANGUAGE_CODE_PT = "PT";
export const LANGUAGE_CODE_EN = "GB";
export const LANGUAGE_CODE_DEFAULT = "-";

export type LanguageCode = typeof LANGUAGE_CODE_DEFAULT | typeof LANGUAGE_CODE_PT | typeof LANGUAGE_CODE_EN;

export const availableLanguages = [ LANGUAGE_CODE_PT, LANGUAGE_CODE_EN ];
export let currentLanguage: LanguageCode = LANGUAGE_CODE_PT;

export function setCurrentLanguage(language: LanguageCode){
    currentLanguage = language;
}

export type TFormater = (arg: any) => string;
export type TString = (arg?: any) => string;

export type LanguageMap = {
    readonly [P in LanguageCode]: string | TFormater;
}

export function TString(map: LanguageMap): TString {
    const getLocalizedString = (): string | TFormater => {
        const str = map[currentLanguage];
        return str ? str : (map[LANGUAGE_CODE_DEFAULT] ?? "");
    }
    return (arg?: any) => {
        const str = getLocalizedString();
        return typeof str === "string" ? str : (str as TFormater)(arg);
    };
}