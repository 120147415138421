import React, { ReactNode } from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    AppBar,
    Grid,
    Toolbar as MaterialToolbar,
    Button,
    Box,
    Typography,
    Modal,
    Divider,
    IconButton
} from "@material-ui/core";
import DraggablePopper from '../navigation/DraggablePopper';
import AccountBox from '@material-ui/icons/AccountBox';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Background from '../../assets/bg.jpg';
import PhotoProfile from '../../assets/foto2.jpg';
import Avatar from '@material-ui/core/Avatar';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';
import GitHubIcon from '@material-ui/icons/GitHub';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../app/state/state';
import { setWindowsOpenned } from '../app/state/actions';
// @ts-ignore
import CV from "../../assets/CV.pdf";
import labels from '../../localization/labels';


const useStyles = makeStyles((theme: Theme) => createStyles({
    heroUnit: {
      backgroundColor: theme.palette.background.paper,
    },
    heroContent: {
      maxWidth: 600,
      margin: '0 auto',
    },
    layout: {
      width: 'auto',
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: 10,
      marginTop: "25%"
    },
}));

const Footer = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const windowsOpenned = useSelector((state: AppState) => state.windowsOpenned);



    const handleOpenWindowSendEmail = () => {
      if(!windowsOpenned.find(item => item == "Enviar Email"))
          dispatch(setWindowsOpenned([...windowsOpenned, "Enviar Email"]));
    }

    const handleDownloadCV = () => {
        const pdfUrl = CV;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "CV(Fábio Pinhal).pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <footer className={classes.footer}>
          <Divider/>
            <Grid container direction='column'justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12}>
                    <Button variant="contained" onClick={handleDownloadCV}>{labels.download_cv()}</Button>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                <IconButton size="medium" href="https://www.linkedin.com/in/f%C3%A1bio-pinhal-70242387/" target={"_blank"}>
                    <LinkedInIcon />
                </IconButton>
                <IconButton size="medium" onClick={handleOpenWindowSendEmail}>
                    <EmailIcon />
                </IconButton>
                <IconButton size="medium">
                    <InstagramIcon />
                </IconButton>
                <IconButton size="medium">
                    <GitHubIcon />
                </IconButton>
            </Grid>
            <Grid container direction='column'justifyContent="center" alignItems="center">
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    
                </Typography>
            </Grid>
        </footer>
    );
}

export default Footer;