import React from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    Grid,
    Box,
} from "@material-ui/core";
import DraggablePopper from '../navigation/DraggablePopper';
import Background from '../../assets/bg.png';
import Background2 from '../../assets/bg2.jpg';
import Background3 from '../../assets/bg3.jpg';
import Background4 from '../../assets/bg4.jpg';
import Background5 from '../../assets/bg5.jpeg';
import Background6 from '../../assets/bg6.jpg';
import Background7 from '../../assets/bg7.jpg';
import Background8 from '../../assets/bg8.jpg';
import Background9 from '../../assets/bg9.jpg';
import Background10 from '../../assets/bg10.jpg';
import { AppState } from '../app/state/state';
import { useDispatch, useSelector } from "react-redux";


export interface WindowBackgroundProps {
    open: boolean;
    onClose: () => void;
    onMinimize: () => void;
    selectBackground: (background: any) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({

}));

const WindowBackground = (props: WindowBackgroundProps) => {
    const { open, onClose, selectBackground, onMinimize } = props;
    const maximixeWindows = useSelector((state: AppState) => state.maximizeWindows);

    const WindowBackgroundView = (
        <Grid container style={{ width: maximixeWindows === "Background" ? "100%" : window.innerWidth / 2}}>
            <div style={{overflowY: 'auto', height: maximixeWindows === "Background" ? window.innerHeight - 215 : window.innerHeight / 2, padding: 10}}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item>
                        <img src={Background} width={300} height={'auto'}  onClick={() => selectBackground(Background)}/>
                    </Grid>
                    <Grid item>
                        <img src={Background2} width={300} height={'auto'} onClick={() => selectBackground(Background2)} />
                    </Grid>
                    <Grid item>
                        <img src={Background3} width={300} height={'auto'} onClick={() => selectBackground(Background3)} />
                    </Grid>
                    <Grid item>
                        <img src={Background4} width={300} height={'auto'} onClick={() => selectBackground(Background4)} />
                    </Grid>
                    <Grid item>
                        <img src={Background5} width={300} height={'auto'} onClick={() => selectBackground(Background5)} />
                    </Grid>
                    <Grid item>
                        <img src={Background6} width={300} height={'auto'}  onClick={() => selectBackground(Background6)}/>
                    </Grid>
                    <Grid item>
                        <img src={Background7} width={300} height={'auto'} onClick={() => selectBackground(Background7)} />
                    </Grid>
                    <Grid item>
                        <img src={Background8} width={300} height={'auto'} onClick={() => selectBackground(Background8)} />
                    </Grid>
                    <Grid item>
                        <img src={Background9} width={300} height={'auto'} onClick={() => selectBackground(Background9)} />
                    </Grid>
                    <Grid item>
                        <img src={Background10} width={300} height={'auto'} onClick={() => selectBackground(Background10)} />
                    </Grid>
                </Grid>
            </div>          
        </Grid>
    );

    return (
        <DraggablePopper
            open={open}
            onMinimize={onMinimize}
            onClose={onClose}
            title={"Background"}
            children={WindowBackgroundView}/>
    )
}

export default WindowBackground;
