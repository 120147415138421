import React from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    AppBar,
    Grid,
    Toolbar as MaterialToolbar,
    Button,
    Box,
    Typography,
    Modal
} from "@material-ui/core";
import InitialBar from '../navigation/InitialBar';
import PersonIcon from '@material-ui/icons/Person';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import CodeIcon from '@material-ui/icons/Code';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import Draggable from 'react-draggable';
import WindowProfile from '../windows/WindowProfile';
import WindowProjects from '../windows/WindowProjects';
import WindowKnowledge from '../windows/WindowKnowledge';
import Background from '../../assets/bg.png';
import WindowExperience from '../windows/WindowExperience';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../app/state/state';
import { setWindowsOpenned } from '../app/state/actions';
import WindowFormation from '../windows/WindowFormation';
import labels from '../../localization/labels';
import useLocalization from '../app/hooks/useLocalization';



const Desktop = () => {

    useLocalization();

    const [open, setOpen] = React.useState(false);
    const [openWindowProfile, setOpenWindowProfile] = React.useState(false);
    const [openWindowProjects, setOpenWindowProjects] = React.useState(false);
    const [openWindowFormation, setOpenWindowFormation] = React.useState(false);
    const [openWindowKnowledge, setOpenWindowKnowledge] = React.useState(false);
    const [openWindowExperience, setOpenWindowExperience] = React.useState(false);

    const [openWindowBackground, setOpenWindowBackground] = React.useState(false);
    const [openWindowChangeColorIcon, setOpenWindowChangeColorIcon] = React.useState(false);
    const [openWindowChangeColorTheme, setOpenWindowChangeColorTheme] = React.useState(false);
    const [openWindowSendEmail, setOpenWindowSendEmail] = React.useState(false);
    const [openWindowBrowser, setOpenWindowBrowser] = React.useState(false);
    const [selectedBackground, setSelectedBackground] = React.useState(Background);
    const colorTheme = useSelector((state: AppState) => state.colorTheme);
    const windowsOpenned = useSelector((state: AppState) => state.windowsOpenned);



    const useStyles = makeStyles((theme: Theme) => createStyles({
        root: {
            backgroundImage: `url(${selectedBackground})`,
            backgroundSize: '100vw 100vh',
            minHeight: '100vh',
            display: 'flex',
            fontSize: 'calc(10px + 2vmin)',
            overflowX: 'hidden',
            overflowY: 'hidden'
        },
        initialBar: {
            flex: 1,
            position: "fixed",
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 999
        },
        button: {
            height: 150 // setting height/width is optional
            
        },
        label: {
            // Aligns the content of the button vertically.
            flexDirection: 'column'
        },
        icon: {
            fontSize: '90px !important',
            color: colorTheme
        },
        text: {
            fontSize: '12px !important'
        }
    }));

    const classes = useStyles();
    const dispatch = useDispatch();

    const onCloseWindowProfile = () => {
        dispatch(setWindowsOpenned(windowsOpenned.filter(item => item !== "Perfil")))
        setOpenWindowProfile(false);
    }

    const onMinimizeWindowProfile = () => {
        setOpenWindowProfile(false);
    }

    const onCloseWindowProjects = () => {
        dispatch(setWindowsOpenned(windowsOpenned.filter(item => item !== "Projetos")))
        setOpenWindowProjects(false);
    }

    const onMinimizeWindowProjects = () => {
        setOpenWindowProjects(false);
    }

    const onCloseWindowKnowledge = () => {
        dispatch(setWindowsOpenned(windowsOpenned.filter(item => item !== "Conhecimento")))
        setOpenWindowKnowledge(false);
    }

    const onMinimizeWindowKnowledge = () => {
        setOpenWindowKnowledge(false);
    }

    const onCloseWindowFormation = () => {
        dispatch(setWindowsOpenned(windowsOpenned.filter(item => item !== "Formação")))
        setOpenWindowFormation(false);
    }

    const onMinimizeWindowFormation = () => {
        setOpenWindowFormation(false);
    }

    const onMinimizeWindowExperience = () => {
        setOpenWindowExperience(false);
    }

    const onCloseWindowExperience = () => {
        dispatch(setWindowsOpenned(windowsOpenned.filter(item => item !== "Experiencia")))
        setOpenWindowExperience(false);
    }

    const selectBackground = (background: any) => {
        setSelectedBackground(background);
    }

    const handleOpenWindowProfile = () => {
        setOpenWindowProfile(!openWindowProfile);
        if(!windowsOpenned.find(item => item == "Perfil"))
            dispatch(setWindowsOpenned([...windowsOpenned, "Perfil"]))
    }

    const handleOpenWindowKnowledge = () => {
        setOpenWindowKnowledge(!openWindowKnowledge);
        if(!windowsOpenned.find(item => item == "Conhecimento"))
            dispatch(setWindowsOpenned([...windowsOpenned, "Conhecimento"]))
    }

    const handleOpenWindowExperience = () => {
        setOpenWindowExperience(!openWindowExperience);
        if(!windowsOpenned.find(item => item == "Experiencia"))
            dispatch(setWindowsOpenned([...windowsOpenned, "Experiencia"]))
    }

    const handleOpenWindowFormation = () => {
        setOpenWindowFormation(!openWindowFormation);
        if(!windowsOpenned.find(item => item == "Formação"))
            dispatch(setWindowsOpenned([...windowsOpenned, "Formação"]))
    }

    const handleOpenWindowProjects = () => {
        setOpenWindowProjects(!openWindowProjects);
        if(!windowsOpenned.find(item => item == "Projetos"))
            dispatch(setWindowsOpenned([...windowsOpenned, "Projetos"]))
    }

    return (
        <div className={classes.root}>
            <Draggable grid={[50, 50]} bounds="parent">
                <Button
                    onDoubleClick={() => handleOpenWindowProfile()}
                    classes={{ root: classes.button, label: classes.label }}
                    color="default"
                    disableRipple={true}>
                    <PersonIcon className={classes.icon} />
                    <Typography className={classes.text}>{labels.profile()}</Typography>
                </Button>
            </Draggable>
            <Draggable grid={[50, 50]} bounds="parent">
                <Button
                    /* Use classes property to inject custom styles */
                    onDoubleClick={() => handleOpenWindowKnowledge()}
                    classes={{ root: classes.button, label: classes.label }}
                    color="default"
                    disableRipple={true}>
                    <EmojiObjectsIcon className={classes.icon} />
                    <Typography className={classes.text}>{labels.knowledge()}</Typography>
                </Button>
            </Draggable>
            <Draggable grid={[50, 50]} bounds="parent">
                <Button
                    onDoubleClick={() => handleOpenWindowFormation()}
                    /* Use classes property to inject custom styles */
                    classes={{ root: classes.button, label: classes.label }}
                    color="default"
                    disableRipple={true}>
                    <SchoolIcon className={classes.icon} />
                    <Typography className={classes.text}>{labels.education()}</Typography>
                </Button>
            </Draggable>
            <Draggable grid={[50, 50]} bounds="parent">
                <Button
                    onDoubleClick={() => handleOpenWindowExperience()}
                    /* Use classes property to inject custom styles */
                    classes={{ root: classes.button, label: classes.label }}
                    color="default"
                    disableRipple={true}>
                    <WorkIcon className={classes.icon} />
                    <Typography className={classes.text}>{labels.experience()}<br/>{labels.professional()}</Typography>
                </Button>
            </Draggable>
            <Draggable grid={[50, 50]} bounds="parent">
                <Button
                    onDoubleClick={() => handleOpenWindowProjects()}
                    /* Use classes property to inject custom styles */
                    classes={{ root: classes.button, label: classes.label }}
                    color="default"
                    disableRipple={true}>
                    <CodeIcon className={classes.icon} />
                    <Typography className={classes.text}>{labels.projects()}</Typography>
                </Button>
            </Draggable>
            {/*<Draggable grid={[50, 50]} bounds="parent">
                <Button
                    onDoubleClick={() => handleOpenWindowProjects()}
                    classes={{ root: classes.button, label: classes.label }}
                    color="default"
                    disableRipple={true}>
                    <CodeIcon className={classes.icon} />
                    <Typography className={classes.text}>Projetos</Typography>
                </Button>
    </Draggable>*/}
            {openWindowProfile && (
                <WindowProfile
                    onMinimize={onMinimizeWindowProfile}
                    open={openWindowProfile}
                    onClose={onCloseWindowProfile} />
            )}
            {openWindowProjects && (
                <WindowProjects
                    onMinimize={onMinimizeWindowProjects}
                    open={openWindowProjects}
                    onClose={onCloseWindowProjects} />
            )}
            {openWindowFormation && (
                <WindowFormation
                    onMinimize={onMinimizeWindowFormation}
                    open={openWindowFormation}
                    onClose={onCloseWindowFormation} />
            )}
            {openWindowKnowledge && (
                <WindowKnowledge
                    onMinimize={onMinimizeWindowKnowledge}
                    open={openWindowKnowledge}
                    onClose={onCloseWindowKnowledge} />
            )}

            {openWindowExperience && (
                <WindowExperience
                    onMinimize={onMinimizeWindowExperience}
                    open={openWindowExperience}
                    onClose={onCloseWindowExperience} />
            )}
            <div className={classes.initialBar}>
                <InitialBar
                    openWindowBackground={openWindowBackground}
                    openWindowChangeColorIcon={openWindowChangeColorIcon}
                    openWindowChangeColorTheme={openWindowChangeColorTheme}
                    openWindowBrowser={openWindowBrowser}
                    openWindowSendEmail={openWindowSendEmail}
                    setOpenWindowBackground={setOpenWindowBackground}
                    setOpenWindowChangeColorIcon={setOpenWindowChangeColorIcon}
                    setOpenWindowChangeColorTheme={setOpenWindowChangeColorTheme}
                    setOpenWindowSendEmail={setOpenWindowSendEmail}
                    setOpenWindowBrowser={setOpenWindowBrowser}
                    setOpenWindowProfile={setOpenWindowProfile}
                    setOpenWindowProjects={setOpenWindowProjects}
                    setOpenWindowKnowledge={setOpenWindowKnowledge}
                    setOpenWindowFormation={setOpenWindowFormation}
                    setOpenWindowExperience={setOpenWindowExperience}
                    selectBackground={selectBackground} />
            </div>
        </div>

    );
}

export default Desktop;
