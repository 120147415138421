import {
    createStyles,
    makeStyles,
    Theme,
    AppBar,
    Toolbar as MaterialToolbar,
    Button,
    Box,
    Grid,
    IconButton
} from "@material-ui/core";
import Clock from 'react-live-clock';
import InitialButton from "./InitialButton";
import CloseIcon from "@material-ui/icons/Close";
import { AppState } from "../app/state/state";
import { useDispatch, useSelector } from "react-redux";
import { setDeephWindows, setWindowsOpenned } from "../app/state/actions";
import { emptyZIndex } from '../../models/ZIndex';
import Language from "./Language";
import useLocalization from "../app/hooks/useLocalization";


export interface InitialBarProps {
    selectBackground: (background: any) => void;
    setOpenWindowProfile: (open: boolean) => void;
    setOpenWindowProjects: (open: boolean) => void;
    setOpenWindowKnowledge: (open: boolean) => void;
    setOpenWindowExperience: (open: boolean) => void;
    setOpenWindowBackground: (open: boolean) => void;
    setOpenWindowChangeColorIcon: (open: boolean) => void;
    setOpenWindowChangeColorTheme: (open: boolean) => void;
    setOpenWindowBrowser: (open: boolean) => void;
    setOpenWindowSendEmail: (open: boolean) => void;
    setOpenWindowFormation: (open: boolean) => void;
    openWindowBackground: boolean;
    openWindowChangeColorIcon: boolean;
    openWindowChangeColorTheme: boolean;
    openWindowBrowser: boolean;
    openWindowSendEmail: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 50,
        backgroundColor: "#2F4F4F"
    },
    tabs: {
        padding: 1,
        minWidth: 100,
        background: "#ffffff",
        color: "#000000",
        fontSize: 14,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'inset 0 0 1em gold',
        cursor: 'pointer'
    }
}));

const InitialBar = (props: InitialBarProps) => {
    useLocalization();
    const classes = useStyles();
    const { selectBackground, 
        setOpenWindowProfile, 
        setOpenWindowProjects, 
        setOpenWindowKnowledge, 
        setOpenWindowBackground,
        setOpenWindowChangeColorIcon,
        setOpenWindowChangeColorTheme,
        setOpenWindowBrowser,
        openWindowBackground,
        openWindowChangeColorIcon,
        openWindowChangeColorTheme,
        setOpenWindowSendEmail,
        openWindowBrowser,
        openWindowSendEmail,
        setOpenWindowExperience,
        setOpenWindowFormation
    } = props;

    const windowsOpenned = useSelector((state: AppState) => state.windowsOpenned);
    const dispatch = useDispatch();


    const handleCloseTab = (tab: string) => {
        if(tab == "Perfil")
            setOpenWindowProfile(false);
        if(tab == "Conhecimento")
            setOpenWindowKnowledge(false);
        if(tab == "Formação")
            setOpenWindowFormation(false);
        if(tab == "Projetos")
            setOpenWindowProjects(false);
        if(tab == "Background")
            setOpenWindowBackground(false);
        if(tab == "Cor Tema")
            setOpenWindowChangeColorIcon(false);
        if(tab == "Browser")
            setOpenWindowBrowser(false);
        if(tab == "Enviar Email")
            setOpenWindowSendEmail(false);
        if(tab == "Experiencia")
            setOpenWindowExperience(false);
        dispatch(setWindowsOpenned(windowsOpenned.filter(item => item !== tab)));
    }

    const handleChooseTab = (tab: string) => {
        if(tab == "Perfil")
            setOpenWindowProfile(true);
        if(tab == "Conhecimento")
            setOpenWindowKnowledge(true);
        if(tab == "Formação")
            setOpenWindowFormation(true);
        if(tab == "Projetos")
            setOpenWindowProjects(true);
        if(tab == "Background")
            setOpenWindowBackground(true);
        if(tab == "Cor Tema")
            setOpenWindowChangeColorIcon(true);
        if(tab == "Browser")
            setOpenWindowBrowser(true);
        if(tab == "Enviar Email")
            setOpenWindowSendEmail(true);
        if(tab == "Experiencia")
            setOpenWindowExperience(true);

        handleDeephWindows(tab);
    }

    const handleDeephWindows = (tab: string) => {
        dispatch(setDeephWindows([...[], {...emptyZIndex, window: tab, deeph: 10}]));
    }

    return (
        <AppBar position="relative" className={classes.root}>
            <MaterialToolbar>
                <Box display="flex" justifyContent="flex-start">
                    <InitialButton 
                        openWindowBackground={openWindowBackground}
                        openWindowChangeColorIcon={openWindowChangeColorIcon}
                        openWindowChangeColorTheme={openWindowChangeColorTheme}
                        openWindowBrowser={openWindowBrowser}
                        openWindowSendEmail={openWindowSendEmail}
                        selectBackground={selectBackground}
                        setOpenWindowBackground={setOpenWindowBackground}
                        setOpenWindowChangeColorIcon={setOpenWindowChangeColorIcon}
                        setOpenWindowChangeColorTheme={setOpenWindowChangeColorTheme}
                        setOpenWindowSendEmail={setOpenWindowSendEmail}
                        setOpenWindowBrowser={setOpenWindowBrowser} />
                </Box>
                <Box display={"flex"} p={2}>
                    <Language />
                </Box>
                <Grid container>
                    {windowsOpenned.map((window, index) => 
                        <Box p={2}  key={index}>
                            <Grid item className={classes.tabs}>
                                <Grid item onClick={() => handleChooseTab(window)}>
                                    {window}
                                </Grid>
                                <IconButton size="small" onClick={() => handleCloseTab(window)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Box>
                    )}
                </Grid>
                <Box flexGrow={1}/>
                <Box display="flex" justifyContent="flex-end">
                    <Clock format={'HH:mm:ss'} ticking={true} timezone={'Europe/Lisbon'} />
                </Box>
            </MaterialToolbar>
        </AppBar>
    )


}

export default InitialBar;