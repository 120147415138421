import React from 'react';
import './App.css';
import Desktop from "../desktop/Desktop";
import {applyMiddleware, compose, legacy_createStore as createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import { Provider } from "react-redux";
import { state } from "./state/reducers";
import { Alert } from '@material-ui/lab';
import {
    Typography,
    Grid
} from "@material-ui/core";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(state, composeEnhancers(applyMiddleware(thunkMiddleware)))


const App = () => {
    return (
        <div className="App-header">
            {(window.innerWidth > 1150 ? (
                <Provider store={store}>
                    <Desktop/>
                </Provider>
            ) : (
                <Grid container justifyContent='center' style={{flex: 1, display: "inline-flex", alignItems: "center"}}>
                    <Alert variant="outlined" severity="info">
                        <Typography style={{fontWeight: "bold"}}>Esta página não está disponível para telas inferiores a 1250px</Typography>
                    </Alert> 
                </Grid>
            ))}
            
        </div>
    );
}

export default App;
