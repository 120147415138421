import React, { ReactNode } from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    AppBar,
    Grid,
    Toolbar as MaterialToolbar,
    Button,
    Box,
    Typography,
    Modal,
    Divider,
    Tabs,
    Tab,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    CardHeader,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Tooltip,
    Fade
} from "@material-ui/core";
import DraggablePopper from '../navigation/DraggablePopper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import AccountBox from '@material-ui/icons/AccountBox';
import Footer from '../footer/Footer';
import InfosLogo from '../../assets/infos-logo.png';
import OrbcomLogo from '../../assets/orbcom_logo.png';
import CovetLogo from '../../assets/covet-logo.png';
import MacroSistemasLogo from '../../assets/macrosistemas-logo.png';
import DevIcon, {iconList} from "devicon-react-svg";
import { AppState } from '../app/state/state';
import { useDispatch, useSelector } from "react-redux";
import labels from '../../localization/labels';
import useLocalization from '../app/hooks/useLocalization';
import messages from '../../localization/messages';
import AdjustIcon from '@material-ui/icons/Adjust';




interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export interface WindowExperienceProps {
    open: boolean;
    onClose: () => void;
    onMinimize: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({

}));

const WindowExperience = (props: WindowExperienceProps) => {
    const { open, onClose, onMinimize } = props;
    useLocalization();
    const [value, setValue] = React.useState(0);
    const maximixeWindows = useSelector((state: AppState) => state.maximizeWindows);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const WindowExperienceView = (
        <Grid container style={{ flexDirection: "column", width: maximixeWindows === "Experiência Profissional" ? "100%" : window.innerWidth / 2 }}>
            <React.Fragment>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <AccountBox />
                    <Typography variant="h6" color="inherit" noWrap>
                        {labels.experience()}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{overflowY: 'auto', height: maximixeWindows === "Experiência Profissional" ? window.innerHeight - 215 : window.innerHeight / 2, padding: 10}}>
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                    >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        style={{ borderRight: 1, borderColor: 'divider', minWidth: 200 }}
                    >
                        <Tab label="Orbcom" {...a11yProps(0)} />
                        <Tab label="Infos" {...a11yProps(1)} />
                        <Tab label="Covet" {...a11yProps(2)} />
                        <Tab label="MacroSistemas" {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Card style={{}}>
                            <CardHeader title={`Orbcom - ${labels.programmer()} Fullstack`}/>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Grid container justifyContent='center'>
                                        <Avatar alt="Orbcom" src={OrbcomLogo} style={{height: 'auto', width: '100%', maxWidth: 400}} />
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                            {labels.june()} 2024 - {labels.present()}
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid container spacing={2} style={{padding: 10}}>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Javascript"}>
                                            <div><DevIcon key={"javascript"} icon={"javascript"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"C#"}>
                                            <div>
                                                <svg viewBox="0 0 128 128" key={"c#"} width={75} height={75} style={{padding: 10, borderRadius: 20}}>
                                                    <path fill="thistle" d="M115.4 30.7L67.1 2.9c-.8-.5-1.9-.7-3.1-.7-1.2 0-2.3.3-3.1.7l-48 27.9c-1.7 1-2.9 3.5-2.9 5.4v55.7c0 1.1.2 2.4 1 3.5l106.8-62c-.6-1.2-1.5-2.1-2.4-2.7z"></path><path fill="thistle" d="M10.7 95.3c.5.8 1.2 1.5 1.9 1.9l48.2 27.9c.8.5 1.9.7 3.1.7 1.2 0 2.3-.3 3.1-.7l48-27.9c1.7-1 2.9-3.5 2.9-5.4V36.1c0-.9-.1-1.9-.6-2.8l-106.6 62z"></path><path fill="#fff" d="M85.3 76.1C81.1 83.5 73.1 88.5 64 88.5c-13.5 0-24.5-11-24.5-24.5s11-24.5 24.5-24.5c9.1 0 17.1 5 21.3 12.5l13-7.5c-6.8-11.9-19.6-20-34.3-20-21.8 0-39.5 17.7-39.5 39.5s17.7 39.5 39.5 39.5c14.6 0 27.4-8 34.2-19.8l-12.9-7.6zM97 66.2l.9-4.3h-4.2v-4.7h5.1L100 51h4.9l-1.2 6.1h3.8l1.2-6.1h4.8l-1.2 6.1h2.4v4.7h-3.3l-.9 4.3h4.2v4.7h-5.1l-1.2 6h-4.9l1.2-6h-3.8l-1.2 6h-4.8l1.2-6h-2.4v-4.7H97zm4.8 0h3.8l.9-4.3h-3.8l-.9 4.3z"></path>
                                                </svg>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"CSS"}>
                                            <div><DevIcon key={"css3"} icon={"css3"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"HTML"}>
                                            <div><DevIcon key={"html5"} icon={"html5"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Bootstrap"}>
                                            <div><DevIcon key={"bootstrap"} icon={"bootstrap"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                               
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Visual Basic"}>
                                                <div>
                                                    <svg viewBox="0 0 128 128" key={"visualbasic"} width={75} height={75} style={{padding: 10, borderRadius: 20}}>
                                                        <path fill="thistle" d="M128 63.121a63.121 63.121 0 01-63.121 63.122A63.121 63.121 0 011.757 63.121 63.121 63.121 0 0164.879 0 63.121 63.121 0 01128 63.121z"></path><path d="M21.127 17.753a63.121 63.121 0 1089.12 89.12z" opacity=".1" fill="#fff"></path><path d="M61.17 38.168L47.125 77.619a21.698 21.698 0 00-1.104 4.498h-.138a23.414 23.414 0 00-1.006-4.398L31.068 38.268H24.58l18.03 49.709h6.488l18.326-49.71-6.254-.099zm13.414.1v49.709h14.44a17.043 17.043 0 0011.538-3.946 13 13 0 004.577-10.336 11.677 11.677 0 00-3.018-8.345 12.644 12.644 0 00-8.049-3.787v-.137a12.348 12.348 0 006.313-4.44 12.092 12.092 0 002.347-7.338 10.198 10.198 0 00-3.787-8.224 15.465 15.465 0 00-10.218-3.156H74.584zm12.525 5.187c6.352 0 9.528 2.414 9.528 7.24a8.028 8.028 0 01-2.742 6.508 11.322 11.322 0 01-7.477 2.367h-5.916V43.514l6.607-.059zM80.502 64.8l6.607.02c7.996 0 11.993 2.931 11.993 8.796a8.304 8.304 0 01-2.82 6.649 11.835 11.835 0 01-7.891 2.425h-7.889V64.8z" fill="#fff"></path>
                                                    </svg>
                                                </div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Angular"}>
                                                <div>
                                                    <svg viewBox="0 0 128 128" key={"angular"} width={75} height={75} style={{padding: 10, borderRadius: 20}}>
                                                        <g id="surface1"> <path fill="thistle" d="M 55.296875 69.324219 L 72.703125 69.324219 L 64 48.382812 Z M 55.296875 69.324219 M 64 15.359375 L 16.332031 32.359375 L 23.601562 95.386719 L 64 117.761719 L 104.398438 95.386719 L 111.667969 32.359375 Z M 93.746094 93.492188 L 82.636719 93.492188 L 76.644531 78.539062 L 51.355469 78.539062 L 45.363281 93.492188 L 34.253906 93.492188 L 64 26.675781 Z M 93.746094 93.492188 "></path> </g>
                                                    </svg>
                                                </div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"TypeScript"}>
                                            <div>
                                                <svg viewBox="0 0 128 128" key={"ts"} width={75} height={75} style={{padding: 10, borderRadius: 20}}>
                                                    <path fill="#fff" d="M22.67 47h99.67v73.67H22.67z"></path><path data-name="original" fill="thistle" d="M1.5 63.91v62.5h125v-125H1.5zm100.73-5a15.56 15.56 0 017.82 4.5 20.58 20.58 0 013 4c0 .16-5.4 3.81-8.69 5.85-.12.08-.6-.44-1.13-1.23a7.09 7.09 0 00-5.87-3.53c-3.79-.26-6.23 1.73-6.21 5a4.58 4.58 0 00.54 2.34c.83 1.73 2.38 2.76 7.24 4.86 8.95 3.85 12.78 6.39 15.16 10 2.66 4 3.25 10.46 1.45 15.24-2 5.2-6.9 8.73-13.83 9.9a38.32 38.32 0 01-9.52-.1 23 23 0 01-12.72-6.63c-1.15-1.27-3.39-4.58-3.25-4.82a9.34 9.34 0 011.15-.73L82 101l3.59-2.08.75 1.11a16.78 16.78 0 004.74 4.54c4 2.1 9.46 1.81 12.16-.62a5.43 5.43 0 00.69-6.92c-1-1.39-3-2.56-8.59-5-6.45-2.78-9.23-4.5-11.77-7.24a16.48 16.48 0 01-3.43-6.25 25 25 0 01-.22-8c1.33-6.23 6-10.58 12.82-11.87a31.66 31.66 0 019.49.26zm-29.34 5.24v5.12H56.66v46.23H45.15V69.26H28.88v-5a49.19 49.19 0 01.12-5.17C29.08 59 39 59 51 59h21.83z"></path>
                                                </svg>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Sql Server"}>
                                            <div><DevIcon key={"msql_server"} icon={"msql_server"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                  
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Git"}>
                                            <div><DevIcon key={"github"} icon={"github"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                            
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Visual Studio"}>
                                            <div><DevIcon key={"visualstudio"} icon={"visualstudio"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                        
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ borderLeft: '0.01em solid black' }}>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={messages.at_Orbcom_i_program_mostly()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.with_these_tools_i_develop_WEB_applications_clients()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.regarding_databases()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon> 
                                            <ListItemText primary={messages.development_backend()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.development_frontend()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.web_application_development()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.api_development_and_integration()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.web_services_development()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.database_management_and_development()}/>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Card>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Card style={{}}>
                            <CardHeader title={`Infos - ${labels.programmer()}/${labels.analyst()}`}/>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Grid container justifyContent='center'>
                                        <Avatar alt="Infos" src={InfosLogo} style={{height: 'auto', width: '100%', maxWidth: 400}} />
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                            {labels.october()} 2020 - {labels.june()} 2024
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid container spacing={2} style={{padding: 10}}>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Java"}>
                                            <div><DevIcon key={"Java"} icon={"java"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Javascript"}>
                                            <div><DevIcon key={"javascript"} icon={"javascript"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"C#"}>
                                            <div>
                                                <svg viewBox="0 0 128 128" key={"c#"} width={75} height={75} style={{padding: 10, borderRadius: 20}}>
                                                    <path fill="thistle" d="M115.4 30.7L67.1 2.9c-.8-.5-1.9-.7-3.1-.7-1.2 0-2.3.3-3.1.7l-48 27.9c-1.7 1-2.9 3.5-2.9 5.4v55.7c0 1.1.2 2.4 1 3.5l106.8-62c-.6-1.2-1.5-2.1-2.4-2.7z"></path><path fill="thistle" d="M10.7 95.3c.5.8 1.2 1.5 1.9 1.9l48.2 27.9c.8.5 1.9.7 3.1.7 1.2 0 2.3-.3 3.1-.7l48-27.9c1.7-1 2.9-3.5 2.9-5.4V36.1c0-.9-.1-1.9-.6-2.8l-106.6 62z"></path><path fill="#fff" d="M85.3 76.1C81.1 83.5 73.1 88.5 64 88.5c-13.5 0-24.5-11-24.5-24.5s11-24.5 24.5-24.5c9.1 0 17.1 5 21.3 12.5l13-7.5c-6.8-11.9-19.6-20-34.3-20-21.8 0-39.5 17.7-39.5 39.5s17.7 39.5 39.5 39.5c14.6 0 27.4-8 34.2-19.8l-12.9-7.6zM97 66.2l.9-4.3h-4.2v-4.7h5.1L100 51h4.9l-1.2 6.1h3.8l1.2-6.1h4.8l-1.2 6.1h2.4v4.7h-3.3l-.9 4.3h4.2v4.7h-5.1l-1.2 6h-4.9l1.2-6h-3.8l-1.2 6h-4.8l1.2-6h-2.4v-4.7H97zm4.8 0h3.8l.9-4.3h-3.8l-.9 4.3z"></path>
                                                </svg>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"React"}>
                                            <div><DevIcon key={"react"} icon={"react"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"SpringBoot"}>
                                            <div>
                                                <svg viewBox="0 0 128 128" width={75} height={75} key={"spring"} style={{padding: 10, borderRadius: 20}}>
                                                    <path d="M116.452 6.643a59.104 59.104 0 01-6.837 12.136A64.249 64.249 0 0064.205-.026C28.984-.026 0 28.982 0 64.242a64.316 64.316 0 0019.945 46.562l2.368 2.1a64.22 64.22 0 0041.358 15.122c33.487 0 61.637-26.24 64.021-59.683 1.751-16.371-3.051-37.077-11.24-61.7zM29.067 111.17a5.5 5.5 0 01-4.269 2.034c-3.018 0-5.487-2.484-5.487-5.502 0-3.017 2.485-5.501 5.487-5.501 1.25 0 2.485.433 3.452 1.234 2.351 1.9 2.718 5.384.817 7.735zm87.119-19.238c-15.843 21.122-49.68 14.003-71.376 15.02 0 0-3.852.234-7.721.867 0 0 1.45-.617 3.335-1.334 15.226-5.301 22.43-6.335 31.685-11.086 17.427-8.869 34.654-28.274 38.24-48.463-6.637 19.422-26.75 36.11-45.077 42.895-12.557 4.635-35.238 9.136-35.238 9.136l-.917-.484c-15.442-7.518-15.91-40.977 12.157-51.78 12.291-4.735 24.048-2.134 37.323-5.302 14.175-3.367 30.568-14.004 37.238-27.874 7.471 22.19 16.46 56.932.35 78.405z" fill="thistle"></path>
                                                </svg>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"CSS"}>
                                            <div><DevIcon key={"css3"} icon={"css3"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"HTML"}>
                                            <div><DevIcon key={"html5"} icon={"html5"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"MaterialUI"}>
                                            <div>
                                                <svg viewBox="0 0 128 128" width={75} height={75} key={"mui"} style={{padding: 10, borderRadius: 20}}>
                                                    <path fill="thistle" d="M.2 68.6V13.4L48 41v18.4L16.1 41v36.8L.2 68.6z"></path><path fill="thistle" d="M48 41l47.9-27.6v55.3L64 87l-16-9.2 32-18.4V41L48 59.4V41z"></path><path fill="thistle" d="M48 77.8v18.4l32 18.4V96.2L48 77.8z"></path><path fill="thistle" d="M80 114.6L127.8 87V50.2l-16 9.2v18.4L80 96.2v18.4zM111.9 41V22.6l16-9.2v18.4l-16 9.2z"></path>
                                                </svg>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"TypeScript"}>
                                            <div>
                                                <svg viewBox="0 0 128 128" key={"ts"} width={75} height={75} style={{padding: 10, borderRadius: 20}}>
                                                    <path fill="#fff" d="M22.67 47h99.67v73.67H22.67z"></path><path data-name="original" fill="thistle" d="M1.5 63.91v62.5h125v-125H1.5zm100.73-5a15.56 15.56 0 017.82 4.5 20.58 20.58 0 013 4c0 .16-5.4 3.81-8.69 5.85-.12.08-.6-.44-1.13-1.23a7.09 7.09 0 00-5.87-3.53c-3.79-.26-6.23 1.73-6.21 5a4.58 4.58 0 00.54 2.34c.83 1.73 2.38 2.76 7.24 4.86 8.95 3.85 12.78 6.39 15.16 10 2.66 4 3.25 10.46 1.45 15.24-2 5.2-6.9 8.73-13.83 9.9a38.32 38.32 0 01-9.52-.1 23 23 0 01-12.72-6.63c-1.15-1.27-3.39-4.58-3.25-4.82a9.34 9.34 0 011.15-.73L82 101l3.59-2.08.75 1.11a16.78 16.78 0 004.74 4.54c4 2.1 9.46 1.81 12.16-.62a5.43 5.43 0 00.69-6.92c-1-1.39-3-2.56-8.59-5-6.45-2.78-9.23-4.5-11.77-7.24a16.48 16.48 0 01-3.43-6.25 25 25 0 01-.22-8c1.33-6.23 6-10.58 12.82-11.87a31.66 31.66 0 019.49.26zm-29.34 5.24v5.12H56.66v46.23H45.15V69.26H28.88v-5a49.19 49.19 0 01.12-5.17C29.08 59 39 59 51 59h21.83z"></path>
                                                </svg>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"MySQL"}>
                                            <div><DevIcon key={"mysql"} icon={"mysql"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Sql Server"}>
                                            <div><DevIcon key={"msql_server"} icon={"msql_server"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                  
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Docker"}>
                                            <div><DevIcon key={"docker"} icon={"docker"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                              
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Git"}>
                                            <div><DevIcon key={"github"} icon={"github"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                            
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Eclipse"}>
                                            <div><DevIcon key={"eclipse"} icon={"eclipse"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                            
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Visual Studio"}>
                                            <div><DevIcon key={"visualstudio"} icon={"visualstudio"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                        
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ borderLeft: '0.01em solid black' }}>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={messages.during_my_career_at_INFOS_and_until_now()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.still_on_my_current_path()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.with_these_tools_i_develop_WEB_applications()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.regarding_databases()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon> 
                                            <ListItemText primary={messages.development_backend()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.development_frontend()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.web_application_development()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.development_of_micro_services()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.api_development_and_integration()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.web_services_development()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.database_management_and_development()}/>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Card>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Card style={{}}>
                        <CardHeader title={`Covet Group - ${labels.programmer()} Fullstack (${labels.intership()})`}/>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Grid container justifyContent='center'>
                                        <Avatar alt="Covet" src={CovetLogo} style={{height: 'auto', width: '100%', maxWidth: 400}} />
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {labels.december()} 2019 - {labels.april()} 2020
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid container spacing={2} style={{padding: 10}}>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Laravel"}>
                                            <div><DevIcon key={"laravel"} icon={"laravel"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"PHP"}>
                                            <div><DevIcon key={"php"} icon={"php"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"VueJs"}>
                                            <div><DevIcon key={"vuejs"} icon={"vuejs"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"CSS"}>
                                            <div><DevIcon key={"css3"} icon={"css3"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"HTML"}>
                                            <div><DevIcon key={"html5"} icon={"html5"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"MySQL"}>
                                            <div><DevIcon key={"mysql"} icon={"mysql"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Bootstrap"}>
                                            <div><DevIcon key={"bootstrap"} icon={"bootstrap"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                               
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ borderLeft: '0.01em solid black',  padding: '0.5em' }}>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={messages.creation_of_a_web_application_from_scratch_to_manage_and_develop_moodboards_in_php_and_javascript_programming_languages_with_the_help_of_laravel_vuejs_and_bootstrap_frameworks()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.the_development_of_this_project_helped_me_a_lot_to_combine_academic_teachings_with_the_business_context()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon> 
                                            <ListItemText primary={messages.development_backend()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.development_frontend()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.web_application_development()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.database_management_and_development()}/>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Card>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                    <Card style={{}}>
                        <CardHeader title={`MacroSistemas, Lda - ${labels.programmer()} (${labels.intership()})`}/>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Grid container justifyContent='center'>
                                        <Avatar alt="MacroSistemas" src={MacroSistemasLogo} style={{height: 'auto', width: '100%', maxWidth: 400}} />
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {labels.april()} 2010 - {labels.july()} 2010
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid container spacing={2} style={{padding: 10}}>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"C#"}>
                                            <div>
                                                <svg viewBox="0 0 128 128" key={"c#"} width={75} height={75} style={{padding: 10, borderRadius: 20}}>
                                                    <path fill="thistle" d="M115.4 30.7L67.1 2.9c-.8-.5-1.9-.7-3.1-.7-1.2 0-2.3.3-3.1.7l-48 27.9c-1.7 1-2.9 3.5-2.9 5.4v55.7c0 1.1.2 2.4 1 3.5l106.8-62c-.6-1.2-1.5-2.1-2.4-2.7z"></path><path fill="thistle" d="M10.7 95.3c.5.8 1.2 1.5 1.9 1.9l48.2 27.9c.8.5 1.9.7 3.1.7 1.2 0 2.3-.3 3.1-.7l48-27.9c1.7-1 2.9-3.5 2.9-5.4V36.1c0-.9-.1-1.9-.6-2.8l-106.6 62z"></path><path fill="#fff" d="M85.3 76.1C81.1 83.5 73.1 88.5 64 88.5c-13.5 0-24.5-11-24.5-24.5s11-24.5 24.5-24.5c9.1 0 17.1 5 21.3 12.5l13-7.5c-6.8-11.9-19.6-20-34.3-20-21.8 0-39.5 17.7-39.5 39.5s17.7 39.5 39.5 39.5c14.6 0 27.4-8 34.2-19.8l-12.9-7.6zM97 66.2l.9-4.3h-4.2v-4.7h5.1L100 51h4.9l-1.2 6.1h3.8l1.2-6.1h4.8l-1.2 6.1h2.4v4.7h-3.3l-.9 4.3h4.2v4.7h-5.1l-1.2 6h-4.9l1.2-6h-3.8l-1.2 6h-4.8l1.2-6h-2.4v-4.7H97zm4.8 0h3.8l.9-4.3h-3.8l-.9 4.3z"></path>
                                                </svg>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Sql Server"}>
                                            <div><DevIcon key={"msql_server"} icon={"msql_server"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Visual Studio"}>
                                            <div><DevIcon key={"visualstudio"} icon={"visualstudio"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                              
                                        </Tooltip>                                
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ borderLeft: '0.01em solid black',  padding: '0.5em' }}>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={messages.creation_of_a_computer_application_from_scratch_in_the_c_programming_language_with_connection_to_a_database_that_allows_the_management_of_potential_customer_contacts_and_the_sending_of_advertising_via_sms_or_email()}/>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Card>
                    </TabPanel>
                </Box>
                <Footer/>
            </div>
            </React.Fragment>
        </Grid>
    );

    return (
        <DraggablePopper
            onMinimize={onMinimize}
            open={open}
            onClose={onClose}
            title={"Experiência Profissional"}
            children={WindowExperienceView}/>
    )
}

export default WindowExperience;
