import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../state/state";
import { setLanguage as setLanguageAction } from "../state/actions";

const useLocalization = () : [ string, (language: string) => void] => {
    const dispatch = useDispatch();
    const language = useSelector((state: AppState) => state.language);


    const setLanguage = (language: string) => {
        dispatch(setLanguageAction(language));
    };

    return [ language, setLanguage ];

}

export default useLocalization;