import React, { ReactNode, useState, useEffect, useRef } from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    AppBar,
    Grid,
    Toolbar as MaterialToolbar,
    Button,
    Box,
    Typography,
    Modal,
    IconButton,
    Card,
    CardContent,
    CardActions,
    CardHeader,
    Divider,
    TextField
} from "@material-ui/core";
import SimpleDialog from '../navigation/DraggableDialog';
import DraggableDialog from '../navigation/DraggableDialog';
import DraggablePopper from '../navigation/DraggablePopper';
import JavaScriptLogo from '../../assets/knowledges/jsDesk.png';
import CSharpLogo from '../../assets/knowledges/cDesk.png';
import JavaLogo from '../../assets/knowledges/javaDesk.png';
import PHPLogo from '../../assets/knowledges/phpDesk.png';
import DevIcon, {iconList} from "devicon-react-svg";
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import AccountBox from '@material-ui/icons/AccountBox';
import Footer from '../footer/Footer';
import { AppState } from '../app/state/state';
import { useSelector } from "react-redux";
import emailjs from '@emailjs/browser';
import Email, { emptyEmail } from '../../models/Email';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useLocalization from '../app/hooks/useLocalization';
import labels from '../../localization/labels';
import messages from '../../localization/messages';

export interface WindowSendEmailProps {
    open: boolean;
    onClose: () => void;
    onMinimize?: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({

}));

const WindowSendEmail = (props: WindowSendEmailProps) => {
    const { open, onClose, onMinimize } = props;
    const maximixeWindows = useSelector((state: AppState) => state.maximizeWindows);
    const [email, setEmail] = useState<Email>(emptyEmail);
    const form = useRef();
    useLocalization();

    const sendEmail = (e) => {

        e.preventDefault();
        if(email.user_name !== "" && email.user_email !== "" && email.message !== "" && email.subject !== ""){
            emailjs.sendForm('service_mexim0c', 'template_jn8qaw5', form.current, '_kKrt74iKKzkTiXTU')
                .then((result) => {
                    toast.success(messages.email_successfully_sent_thanks());
                }, (error) => {
                    toast.error(messages.an_error_occurred_while_sending_the_email_please_try_again_later_or_you_can_send_an_email_to_fpinhal_live_com_pt());
                }
            );
            e.target.reset();
            setEmail(emptyEmail);
        }else{
            toast.error(messages.all_fields_are_mandatory());
            e.target.reset();
        }
    };

    const WindowSendEmailView = (
        <Grid container style={{ flexDirection: "column", width: maximixeWindows === "Enviar Email" ? "100%" : window.innerWidth / 2 }}>
            <React.Fragment>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <AccountBox />
                    <Typography variant="h6" color="inherit" noWrap>
                        {labels.send_email()}
                    </Typography>
                </Toolbar>
            </AppBar>
            <form ref={form} onSubmit={sendEmail}>
                <div style={{overflowY: 'auto', height: maximixeWindows === "Enviar Email" ? window.innerHeight - 215 : window.innerHeight / 2, padding: 10}}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                name='user_name'
                                fullWidth
                                label={labels.name()}
                                variant="filled"
                                size="medium"
                                onChange={(e) => setEmail({...email, user_name: e.target.value})}
                                value={email.user_name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                name='user_email'
                                fullWidth
                                label={labels.email()}
                                variant="filled"
                                size="medium"
                                onChange={(e) => setEmail({...email, user_email: e.target.value})}
                                value={email.user_email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                name='subject'
                                fullWidth
                                label={labels.subject()}
                                variant="filled"
                                size="medium"
                                onChange={(e) => setEmail({...email, subject: e.target.value})}
                                value={email.subject}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                name='message'
                                fullWidth
                                label={labels.message()}
                                variant="filled"
                                size="medium"
                                multiline
                                rows={10}
                                onChange={(e) => setEmail({...email, message: e.target.value})}
                                value={email.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={2}  justifyContent="center" alignItems="center">
                                <Grid item xs={6} sm={6} md={6}>
                                    <Button fullWidth variant="contained" color='primary' type='submit'>{labels.send()}</Button>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Button fullWidth variant="contained" onClick={() => setEmail(emptyEmail)}>{labels.clear()}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Footer/>
                </div>
                <ToastContainer />
            </form>
            </React.Fragment>
        </Grid>
    );

    return (
        <DraggablePopper
            onMinimize={onMinimize}
            open={open}
            onClose={onClose}
            title={"Enviar Email"}
            children={WindowSendEmailView}/>
    )
}

export default WindowSendEmail;
