import React, { ReactNode } from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    AppBar,
    Grid,
    Toolbar as MaterialToolbar,
    Button,
    Box,
    Typography,
    Modal,
    Divider,
    Tabs,
    Tab,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    CardHeader,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar
} from "@material-ui/core";
import DraggablePopper from '../navigation/DraggablePopper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import AccountBox from '@material-ui/icons/AccountBox';
import Footer from '../footer/Footer';
import IspgayaLogo from '../../assets/ispgaya.png';
import EspeLogo from '../../assets/espe.png';
import DevIcon, {iconList} from "devicon-react-svg";
import { AppState } from '../app/state/state';
import { useDispatch, useSelector } from "react-redux";
import labels from '../../localization/labels';
import useLocalization from '../app/hooks/useLocalization';
import messages from '../../localization/messages';
import AdjustIcon from '@material-ui/icons/Adjust';




interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export interface WindowFormationProps {
    open: boolean;
    onClose: () => void;
    onMinimize: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({

}));

const WindowFormation = (props: WindowFormationProps) => {
    const { open, onClose, onMinimize } = props;
    useLocalization();
    const [value, setValue] = React.useState(0);
    const maximixeWindows = useSelector((state: AppState) => state.maximizeWindows);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const WindowFormationView = (
        <Grid container style={{ flexDirection: "column", width: maximixeWindows === "Formação Académica" ? "100%" : window.innerWidth / 2 }}>
            <React.Fragment>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <AccountBox />
                    <Typography variant="h6" color="inherit" noWrap>
                        Formação Académica
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{overflowY: 'auto', height: maximixeWindows === "Formação Académica" ? window.innerHeight - 215 : window.innerHeight / 2, padding: 10}}>
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                    >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        style={{ borderRight: 1, borderColor: 'divider', minWidth: 200 }}
                    >
                        <Tab label="IspGaya" {...a11yProps(0)} />
                        <Tab label="ESPE" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Card style={{}}>
                            <CardHeader title={messages.computer_engineering_degree()}/>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Grid container justifyContent='center'>
                                        <Avatar alt="IspGaya" src={IspgayaLogo} style={{height: 'auto', width: '100%', maxWidth: 400}} />
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {labels.september()} 2017 - {labels.july()} 2020    
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ borderLeft: '0.01em solid black',  padding: '0.5em', width: maximixeWindows === "Formação Académica" ? 1000 : 300 }}>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.math_analysis()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.programming_and_computers()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.computing_languages_and_theories()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.computing_languages_and_paradigms()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.computer_architecture()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.mobile_computing()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.operating_systems()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.applications_web_technologies()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.information_processing()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.databases()}/>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Card>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Card style={{}}>
                        <CardHeader title={messages.management_it_technician_professional_course()}/>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Grid container justifyContent='center'>
                                        <Avatar alt="ESPE" src={EspeLogo} style={{height: 'auto', width: '100%', maxWidth: 400}} />
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                            {labels.september()} 2007 - {labels.july()} 2011  
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ borderLeft: '0.01em solid black',  padding: '0.5em', width: maximixeWindows === "Formação Académica" ? 1000 : 300 }}>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.math()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.information_systems()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.programming_language()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.information_analysis_and_exploration_systems()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.information_and_communication_technologies()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon> 
                                                <AdjustIcon /> 
                                            </ListItemIcon>
                                            <ListItemText primary={messages.economy()}/>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Card>
                    </TabPanel>
                </Box>
                <Footer/>
            </div>
            </React.Fragment>
        </Grid>
    );

    return (
        <DraggablePopper
            onMinimize={onMinimize}
            open={open}
            onClose={onClose}
            title={"Formação Académica"}
            children={WindowFormationView}/>
    )
}

export default WindowFormation;
