import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ListIcon from '@material-ui/icons/List';
import WindowBackground from '../windows/WindowBackground';
import WindowBrowser from '../windows/WindowBrowser';
import WindowSendEmail from '../windows/WindowSendEmail';
import WindowChangeIconColor from '../windows/WindowChangeIconColor';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../app/state/state';
import { setWindowsOpenned } from '../app/state/actions';
import EmailIcon from '@material-ui/icons/Email';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import ImageIcon from '@material-ui/icons/Image';
import useLocalization from '../app/hooks/useLocalization';
import labels from '../../localization/labels';

export interface InitialButtonProps {
    openWindowBackground: boolean;
    openWindowChangeColorIcon: boolean;
    openWindowChangeColorTheme: boolean;
    openWindowBrowser: boolean;
    openWindowSendEmail: boolean;
    setOpenWindowBackground: (open: boolean) => void;
    setOpenWindowChangeColorIcon: (open: boolean) => void;
    setOpenWindowChangeColorTheme: (open: boolean) => void;
    setOpenWindowBrowser: (open: boolean) => void;
    setOpenWindowSendEmail: (open: boolean) => void;
    selectBackground: (background: any) => void;
}


const StyledMenu = withStyles({
    paper: {
        position: 'fixed',
        marginLeft: -25,
        width: 360,
        marginTop: -30,
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.palette.common.white,
        },
        },
    },
}))(MenuItem);


const InitialButton = (props: InitialButtonProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const windowsOpenned = useSelector((state: AppState) => state.windowsOpenned);

    const dispatch = useDispatch();
    useLocalization();

    const {
        selectBackground, 
        setOpenWindowBackground, 
        setOpenWindowChangeColorIcon,
        setOpenWindowChangeColorTheme,
        setOpenWindowBrowser,
        setOpenWindowSendEmail,
        openWindowBackground, 
        openWindowChangeColorIcon,
        openWindowChangeColorTheme,
        openWindowBrowser,
        openWindowSendEmail
     } = props;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onCloseWindowBackground = () => {
        dispatch(setWindowsOpenned(windowsOpenned.filter(item => item !== "Background")));
        setOpenWindowBackground(false);
    }

    const handleOpenWindowBackground = () => {
        if(!windowsOpenned.find(item => item == "Background"))
            dispatch(setWindowsOpenned([...windowsOpenned, "Background"]));
        setOpenWindowBackground(!openWindowBackground)
        setAnchorEl(null);
    }

    const handleOpenWindowChangeIconColor = () => {
        if(!windowsOpenned.find(item => item == "Cor Tema"))
            dispatch(setWindowsOpenned([...windowsOpenned, "Cor Tema"]));
        setOpenWindowChangeColorIcon(!openWindowChangeColorIcon)
        setAnchorEl(null);
    }

    const onCloseWindowChangeIconColor = () => {
        dispatch(setWindowsOpenned(windowsOpenned.filter(item => item !== "Cor Tema")));
        setOpenWindowChangeColorIcon(false);
    }

    const onCloseWindowBrowser = () => {
        dispatch(setWindowsOpenned(windowsOpenned.filter(item => item !== "Browser")));
        setOpenWindowBrowser(false);
    }

    const handleOpenWindowBrowser = () => {
        if(!windowsOpenned.find(item => item == "Browser"))
            dispatch(setWindowsOpenned([...windowsOpenned, "Browser"]));
        setOpenWindowBrowser(!openWindowBrowser)
        setAnchorEl(null);
    }

    const onCloseWindowSendEmail = () => {
        dispatch(setWindowsOpenned(windowsOpenned.filter(item => item !== "Enviar Email")));
        setOpenWindowSendEmail(false);
    }

    const handleOpenWindowSendEmail = () => {
        if(!windowsOpenned.find(item => item == "Enviar Email"))
            dispatch(setWindowsOpenned([...windowsOpenned, "Enviar Email"]));
        setOpenWindowSendEmail(!openWindowSendEmail)
        setAnchorEl(null);
    }

    const onMinimizeWindowBackground = () => {
        setOpenWindowBackground(false);
    }

    const onMinimizeWindowChangeIconColor = () => {
        setOpenWindowChangeColorIcon(false);
    }

    const onMinimizeWindowBrowser = () => {
        setOpenWindowBrowser(false);
    }

    const onMinimizeWindowSendEmail = () => {
        setOpenWindowSendEmail(false);
    }

    return (
        <div>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                startIcon={<ListIcon/>}
                onClick={handleClick}
            >
                {labels.start()}
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose} >
                <StyledMenuItem>
                    <ListItemIcon>
                        <ImageIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={labels.change_background()} onClick={() => handleOpenWindowBackground()}/>
                </StyledMenuItem>
                <StyledMenuItem>
                    <ListItemIcon>
                        <ColorLensIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={labels.change_color()} onClick={() => handleOpenWindowChangeIconColor()}/>
                </StyledMenuItem>
                {/*<StyledMenuItem>
                    <ListItemIcon>
                        <DraftsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Internet" onClick={() => handleOpenWindowBrowser()} />
    </StyledMenuItem>*/}
                <StyledMenuItem>
                    <ListItemIcon>
                        <EmailIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={labels.send_email()}  onClick={() => handleOpenWindowSendEmail()}/>
                </StyledMenuItem>
            </StyledMenu>
            <WindowBackground
                onMinimize={onMinimizeWindowBackground}
                selectBackground={selectBackground}
                open={openWindowBackground}
                onClose={onCloseWindowBackground} />
            <WindowChangeIconColor
                onMinimize={onMinimizeWindowChangeIconColor}
                open={openWindowChangeColorIcon}
                onClose={onCloseWindowChangeIconColor} />
            <WindowBrowser
                onMinimize={onMinimizeWindowBrowser}
                open={openWindowBrowser}
                onClose={onCloseWindowBrowser} />
            <WindowSendEmail
                onMinimize={onMinimizeWindowSendEmail}
                open={openWindowSendEmail}
                onClose={onCloseWindowSendEmail} />
        </div>
    );
}

export default InitialButton;