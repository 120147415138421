import React from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    Grid,
    Box,
    Container,
} from "@material-ui/core";
import DraggablePopper from '../navigation/DraggablePopper';
import Background from '../../assets/bg.png';
import Background2 from '../../assets/bg2.jpg';
import Background3 from '../../assets/bg3.jpg';
import Background4 from '../../assets/bg4.jpg';
import Background5 from '../../assets/bg5.jpeg';
import { ColorPicker, useColor, Color } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { useDispatch } from 'react-redux';
import { setColorTheme } from '../app/state/actions';

export interface WindowChangeIconColorProps {
    open: boolean;
    onClose: () => void;
    onMinimize: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({

}));

const WindowChangeIconColor = (props: WindowChangeIconColorProps) => {
    const { open, onClose, onMinimize } = props;
    const [color, setColor] = useColor("hex", "#606060");
    const dispatch = useDispatch();

    const handleChooseColor = (color: Color) => {
        setColor(color);
        dispatch(setColorTheme(color.hex));
    }

    const WindowChangeIconColor = (
        <ColorPicker width={window.innerWidth / 2} height={window.innerHeight / 2} color={color} onChange={(e) => handleChooseColor(e)} hideHSV dark />
    )

    return (
        <DraggablePopper
            open={open}
            disabledMaximize
            onMinimize={onMinimize}
            onClose={onClose}
            title={"Alterar Cor do Tema"}
            children={WindowChangeIconColor}/>
    )
}

export default WindowChangeIconColor;
