export default class Email{
    user_name: string;
    user_email: string;
    subject: string;
    message: string;
}

export const emptyEmail : Email = {
    user_name: "",
    user_email: "",
    subject: "",
    message: ""
}