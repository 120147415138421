import { useDispatch } from "react-redux";
import React, {Fragment, useState} from "react";
import useLocalization from "../app/hooks/useLocalization"
import { AppState } from "../app/state/state";
import { Tooltip, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import CheckIcon from "@material-ui/icons/Check";
import { availableLanguages, currentLanguage } from "../../localization/core";
import ReactCountryFlag from "react-country-flag";
import labels from "../../localization/labels";

const Language = () => {
    const [ language, setLanguage ] = useLocalization();
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);
    const openMenu = Boolean(anchorEl);
    useLocalization();

    const handleLanguageSelect = (language: string) => {
        setLanguage(language);
        setAnchorEl(null);
    }

    const handleLanguageChoose = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    return(
        <Fragment>
            <Tooltip title={labels.language()}>
                <IconButton onClick={handleLanguageChoose}>
                    <LanguageIcon fontSize={"large"} style={{color: "#FFF"}} />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-language"
                style={{marginTop: "45px"}}
                anchorEl={(anchorEl)}
                keepMounted
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
            >
                <div>
                    {availableLanguages.map((lang, index) => (
                        <MenuItem key={index} selected={lang === currentLanguage} onClick={() => handleLanguageSelect(lang)}>
                            <ReactCountryFlag countryCode={lang} style={{fontSize: "1.5em", lineHeight: "2em"}} svg />
                            <Typography style={{textAlign: "center", padding: 2}}>{lang}</Typography>
                            {currentLanguage === lang && (<CheckIcon />)}
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        </Fragment>
    )
}

export default Language;