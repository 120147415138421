import { LANGUAGE_CODE_DEFAULT, LANGUAGE_CODE_PT, LANGUAGE_CODE_EN, TString } from "./core";

export default {
    name: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Name",
        [LANGUAGE_CODE_DEFAULT]: "Nome"
    }),
    profile: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Profile",
        [LANGUAGE_CODE_DEFAULT]: "Perfil"
    }),
    programmer: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Programmer",
        [LANGUAGE_CODE_DEFAULT]: "Programador"
    }),
    analyst: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Analyst",
        [LANGUAGE_CODE_DEFAULT]: "Analista"
    }),
    language: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Language",
        [LANGUAGE_CODE_DEFAULT]: "Linguagem"
    }),
    languages: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Languages",
        [LANGUAGE_CODE_DEFAULT]: "Linguagens"
    }),
    intership: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Intership",
        [LANGUAGE_CODE_DEFAULT]: "Estágio"
    }),
    see_project: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "See Project",
        [LANGUAGE_CODE_DEFAULT]: "Ver Projeto"
    }),
    frameworks: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Frameworks",
        [LANGUAGE_CODE_DEFAULT]: "Frameworks"
    }),
    tools: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Tools",
        [LANGUAGE_CODE_DEFAULT]: "Ferramentas"
    }),
    databases: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Databases",
        [LANGUAGE_CODE_DEFAULT]: "Bases de Dados"
    }),
    knowledge: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Knowledge",
        [LANGUAGE_CODE_DEFAULT]: "Conhecimento"
    }),
    education: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Education",
        [LANGUAGE_CODE_DEFAULT]: "Formação"
    }),
    professional: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Professional",
        [LANGUAGE_CODE_DEFAULT]: "Profissional"
    }),
    experience: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Experience",
        [LANGUAGE_CODE_DEFAULT]: "Experiência"
    }),
    start: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Start",
        [LANGUAGE_CODE_DEFAULT]: "Iniciar"
    }),
    change_background: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Change Background",
        [LANGUAGE_CODE_DEFAULT]: "Mudar Background"
    }),
    change_color: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Change Color",
        [LANGUAGE_CODE_DEFAULT]: "Mudar Cor"
    }),
    send_email: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Send Email",
        [LANGUAGE_CODE_DEFAULT]: "Enviar Email"
    }),
    email: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Email",
        [LANGUAGE_CODE_DEFAULT]: "Email"
    }),
    subject: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Subject",
        [LANGUAGE_CODE_DEFAULT]: "Assunto"
    }),
    message: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Message",
        [LANGUAGE_CODE_DEFAULT]: "Mensagem"
    }),
    send: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Send",
        [LANGUAGE_CODE_DEFAULT]: "Enviar"
    }),
    clear: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Clear",
        [LANGUAGE_CODE_DEFAULT]: "Limpar"
    }),
    download_cv: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Download CV",
        [LANGUAGE_CODE_DEFAULT]: "Download CV"
    }),
    football: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Football",
        [LANGUAGE_CODE_DEFAULT]: "Futebol"
    }),
    program: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Program",
        [LANGUAGE_CODE_DEFAULT]: "Programar"
    }),
    beach: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Beach",
        [LANGUAGE_CODE_DEFAULT]: "Praia"
    }),
    videogames: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Video Games",
        [LANGUAGE_CODE_DEFAULT]: "Video Jogos"
    }),
    music: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Music",
        [LANGUAGE_CODE_DEFAULT]: "Música"
    }),
    reset: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Reset",
        [LANGUAGE_CODE_DEFAULT]: "Reiniciar"
    }),
    score: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Score",
        [LANGUAGE_CODE_DEFAULT]: "Pontuação"
    }),
    present: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Present",
        [LANGUAGE_CODE_DEFAULT]: "Presente"
    }),
    april: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "April",
        [LANGUAGE_CODE_DEFAULT]: "Abril"
    }),
    december: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "December",
        [LANGUAGE_CODE_DEFAULT]: "Dezembro"
    }),
    june: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "June",
        [LANGUAGE_CODE_DEFAULT]: "Junho"
    }),
    july: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "July",
        [LANGUAGE_CODE_DEFAULT]: "Julho"
    }),
    october: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "October",
        [LANGUAGE_CODE_DEFAULT]: "Outubro"
    }),
    september: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "September",
        [LANGUAGE_CODE_DEFAULT]: "Setembro"
    }),
    november: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "November",
        [LANGUAGE_CODE_DEFAULT]: "Novembro"
    }),
    projects: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Projects",
        [LANGUAGE_CODE_DEFAULT]: "Projetos"
    }),
    developed: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Developed",
        [LANGUAGE_CODE_DEFAULT]: "Desenvolvido"
    }),
    between: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "between",
        [LANGUAGE_CODE_DEFAULT]: "entre"
    }),
}