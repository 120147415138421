import React, { ReactNode, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Draggable from 'react-draggable';
import { Box, Divider, Fade, Grid, Grow, IconButton, Typography } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import MinimizeIcon from '@material-ui/icons/Minimize';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from '../app/state/state';
import { setDeephWindows, setMaximizeWindows } from '../app/state/actions';
import { emptyZIndex } from '../../models/ZIndex';

export interface DraggablePopperProps {
    title: string;
    open: boolean;
    children: ReactNode;
    maxWidth?: false;
    disabledMaximize?: boolean;
    onOk?: () => void;
    onClose: () => void;
    onMinimize?: () => void;
    onDismiss?: () => void;
    onEnter?: () => void;
}

const DraggablePopper = (props: DraggablePopperProps) => {

    const { title, open, children, maxWidth, onOk, onClose, onDismiss, onEnter, onMinimize, disabledMaximize } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [popperWidth, setPopperWidth] = React.useState(window.innerWidth / 2);
    const [popperHeight, setPopperHeight] = React.useState(window.innerHeight / 2);
    const [maximizated, setMaximizated] = React.useState(true);
    //const [zIndex, setZIndex] = React.useState(0);
    const deephWindows = useSelector((state: AppState) => state.deephWindows);
    const colorTheme = useSelector((state: AppState) => state.colorTheme);


    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            paper: {
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                minWidth: popperWidth,
                minHeight: popperHeight,
                border: '1px solid',
                backgroundColor: theme.palette.background.paper,
                transition: 'opacity 2.5s'
            },
            toolbar: {
                padding: 5,
                backgroundColor: colorTheme ? colorTheme : "#808080"
            },
            popper: {
                top: 0,
                left: 0,
                zIndex: deephWindows?.find(x => x.window === title)?.deeph
            }
        }),
    );

    const classes = useStyles();
    const dispatch = useDispatch();

    const maximixeWindows = useSelector((state: AppState) => state.maximizeWindows);

    const changeSizeOfPopper = () => {
        setMaximizated(!maximizated);
        if(maximizated){
            setPopperWidth(window.innerWidth);
            //window.innerHeight - 50
            setPopperHeight(window.innerHeight);
            dispatch(setMaximizeWindows(title));
        }
        else{
            setPopperWidth(window.innerWidth / 2);
            setPopperHeight(window.innerHeight / 2);
            dispatch(setMaximizeWindows(""));
        }
    }

    const handleDeephWindows = () => {
        dispatch(setDeephWindows([...[], {...emptyZIndex, window: title, deeph: 10}]));
    }

    return (
        <div>
            <Draggable bounds="parent" defaultPosition={{x: 0, y: 0}} onStart={() => {handleDeephWindows()}}>
                <Popper open={open} anchorEl={anchorEl} onDoubleClick={() => !disabledMaximize && changeSizeOfPopper()} className={classes.popper}>
                <Grow in={open} exit={!open} style={{ 
                    transformOrigin: '0 0 0' 
                }}
                {...(open ? { timeout: 1000 } : {timeout: 1000})}>
                    <div className={classes.paper}>
                        <Grid container className={classes.toolbar}>
                            <Grid item md={10}>
                                <Box display="flex" justifyContent="flex-start">
                                    <Typography>{title}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={2}>
                                <Box display="flex" justifyContent="flex-end">
                                    <IconButton size="small" onClick={onMinimize}>
                                        <MinimizeIcon />
                                    </IconButton>
                                    {maximizated ? (
                                        <IconButton size="small" onClick={changeSizeOfPopper} disabled={disabledMaximize}>
                                            <CropSquareIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton size="small" onClick={changeSizeOfPopper} disabled={disabledMaximize}>
                                            <SelectAllIcon />
                                        </IconButton>
                                    )}
                                    
                                    <IconButton size="small" onClick={onClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Grid>  
                        </Grid>
                        <Divider/>
                        {children}
                    </div>
                    </Grow>
                </Popper>
            </Draggable>
        </div>
    );
}

export default DraggablePopper;