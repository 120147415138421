import React, { ReactNode } from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    Grid,
    TextField,
    Button,
    Box
} from "@material-ui/core";
import DraggablePopper from '../navigation/DraggablePopper';

export interface WindowBrowserProps {
    open: boolean;
    onClose: () => void;
    onMinimize: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({

}));

const WindowBrowser = (props: WindowBrowserProps) => {
    const { open, onClose, onMinimize } = props;

    const [url, setUrl] = React.useState("https://www.zerozero.pt/");
    const [value, setValue] = React.useState<string>("");

    const WindowBrowserView = (
        <div>
            <Grid container>
                <Grid item md={10}>
                    <Box p={2}>
                        <TextField fullWidth size="small" label="Url" variant="outlined" onChange={(e) => setValue(e.target.value)} />
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box p={2}>
                        <Button fullWidth variant="contained" color='primary' onClick={() => setUrl(value)}>OK</Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    <iframe src={url} width={window.innerWidth} height={window.innerHeight - 165}></iframe>
                </Grid>
            </Grid>
        </div>
    );

    return (
        <DraggablePopper
            disabledMaximize={true}
            onMinimize={onMinimize}
            open={open}
            onClose={onClose}
            title={"Browser"}
            children={WindowBrowserView}/>
    )
}

export default WindowBrowser;
