import { LANGUAGE_CODE_DEFAULT, LANGUAGE_CODE_PT, LANGUAGE_CODE_EN, TString } from "./core";

export default {
    presentation_profile: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Fullstack programmer with a degree in Computer Engineering from the Instituto Superior Politécnico de Gaya (ISPGAYA), I live in Mozelos (Santa Maria da Feira) and am originally from Espinho, and I am currently working at Orbcom on a full-time basis.",
        [LANGUAGE_CODE_DEFAULT]: "Programador fullstack licenciado em Engenharia Informática pelo Instituto Superior Politécnico de Gaya(ISPGAYA), resido em Mozelos(Santa Maria da Feira) sendo natural de Espinho, e neste momento encontro me a exercer funções na Orbcom em regime integral."
    }),
    by_clicking_on_the_icons_it_is_possible_to_view_my_self_assessment_regarding_the_selected_items: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "By clicking on the icons, it is possible to view my self-assessment regarding the selected items!",
        [LANGUAGE_CODE_DEFAULT]: "Clicando nos icones, é possivel visualizar a minha auto avaliação relativamente aos items selecionados!"
    }),
    requirements_gathering: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Requirements gathering",
        [LANGUAGE_CODE_DEFAULT]: "Levantamento de Requisitos"
    }),
    web_application_development: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Web Application Development",
        [LANGUAGE_CODE_DEFAULT]: "Desenvolvimento de Aplicações Web"
    }),
    development_of_micro_services: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Development of Micro Services",
        [LANGUAGE_CODE_DEFAULT]: "Desenvolvimento de Micro Serviços"
    }),
    development_backend: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Development Backend",
        [LANGUAGE_CODE_DEFAULT]: "Desenvolvimento Backend"
    }),
    development_frontend: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Development Frontend",
        [LANGUAGE_CODE_DEFAULT]: "Desenvolvimento Frontend"
    }),
    web_services_development: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Web Services Development",
        [LANGUAGE_CODE_DEFAULT]: "Desenvolvimento de Web Services"
    }),
    api_development_and_integration: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "API development and integration",
        [LANGUAGE_CODE_DEFAULT]: "Desenvolvimento e integração de API's"
    }),
    database_management_and_development: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Database management and development",
        [LANGUAGE_CODE_DEFAULT]: "Gestão e desenvolvimento de bases de dados"
    }),
    creation_of_a_web_application_from_scratch_to_manage_and_develop_moodboards_in_php_and_javascript_programming_languages_with_the_help_of_laravel_vuejs_and_bootstrap_frameworks: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Creation of a web application from scratch to manage and develop Moodboards in PHP and Javascript Programming Languages with the help of Laravel, VueJs and Bootstrap frameworks.",
        [LANGUAGE_CODE_DEFAULT]: "Criação de uma Aplicação web de raiz para gerir e desenvolver Moodboards nas Linguagens de Programação PHP e Javascript com auxílio das frameworks Laravel, VueJs e Bootstrap."
    }),
    the_development_of_this_project_helped_me_a_lot_to_combine_academic_teachings_with_the_business_context: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "The development of this project helped me a lot to combine academic teachings with the business context.",
        [LANGUAGE_CODE_DEFAULT]: "O desenvolvimento deste projeto ajudou me bastante a consiliar os ensinamentos académicos com o contexto empresarial."
    }),
    creation_of_a_computer_application_from_scratch_in_the_c_programming_language_with_connection_to_a_database_that_allows_the_management_of_potential_customer_contacts_and_the_sending_of_advertising_via_sms_or_email: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Creation of a Computer Application from scratch in the C# Programming Language with connection to a database that allows the management of potential customer contacts and the sending of advertising via SMS or email.",
        [LANGUAGE_CODE_DEFAULT]: "Criação de uma Aplicação Informática de raiz na Linguagem de Programação C# com ligação a base de dados que permite a gestão de contactos de potenciais clientes e o envio de publicidade via sms ou e-mail."
    }),
    math_analysis: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Math analysis",
        [LANGUAGE_CODE_DEFAULT]: "Análise Matemática"
    }),
    programming_and_computers: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Programming and Computers",
        [LANGUAGE_CODE_DEFAULT]: "Programação e Computadores"
    }),
    computing_languages_and_theories: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Computing Languages and Theories",
        [LANGUAGE_CODE_DEFAULT]: "Linguagens e Teorias da Computação"
    }),
    computing_languages_and_paradigms: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Computing Languages and Paradigms",
        [LANGUAGE_CODE_DEFAULT]: "Linguagens e Paradigmas da Computação"
    }),
    computer_architecture: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Computer Architecture",
        [LANGUAGE_CODE_DEFAULT]: "Arquitetura de Computadores"
    }),
    mobile_computing: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Mobile Computing",
        [LANGUAGE_CODE_DEFAULT]: "Computação Móvel"
    }),
    operating_systems: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Operating Systems",
        [LANGUAGE_CODE_DEFAULT]: "Sistemas Operativos"
    }),
    applications_web_technologies: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Applications WEB Technologies",
        [LANGUAGE_CODE_DEFAULT]: "Aplicações Tecnologias WEB"
    }),
    information_processing: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Information Processing",
        [LANGUAGE_CODE_DEFAULT]: "Processamento de Informação"
    }),
    databases: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Databases",
        [LANGUAGE_CODE_DEFAULT]: "Bases de dados"
    }),
    math: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Math",
        [LANGUAGE_CODE_DEFAULT]: "Matemática"
    }),
    information_systems: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Information systems",
        [LANGUAGE_CODE_DEFAULT]: "Sistemas de Informação"
    }),
    programming_language: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Programming language",
        [LANGUAGE_CODE_DEFAULT]: "Linguagem de Programação"
    }),
    information_analysis_and_exploration_systems: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Information Analysis and Exploration Systems",
        [LANGUAGE_CODE_DEFAULT]: "Análise de Informação e Sistemas de Exploração"
    }),
    information_and_communication_technologies: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Information and Communication Technologies",
        [LANGUAGE_CODE_DEFAULT]: "Tecnologias de Informação e Comunicação"
    }),
    economy: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Economy",
        [LANGUAGE_CODE_DEFAULT]: "Economia"
    }),
    computer_engineering_degree: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Computer Engineering Degree",
        [LANGUAGE_CODE_DEFAULT]: "Licenciatura Engenharia Informática"
    }),
    management_it_technician_professional_course: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Management IT Technician Professional Course",
        [LANGUAGE_CODE_DEFAULT]: "Curso Profissional Técnico de Informática de Gestão"
    }),
    all_fields_are_mandatory: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "All fields are mandatory!",
        [LANGUAGE_CODE_DEFAULT]: "Todos os campos são obrigatórios!"
    }),
    an_error_occurred_while_sending_the_email_please_try_again_later_or_you_can_send_an_email_to_fpinhal_live_com_pt: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "An error occurred while sending the email. Please try again later or you can send an email to fpinhal@live.com.pt",
        [LANGUAGE_CODE_DEFAULT]: "Ocorreu um erro no envio do email. Tente novamente mais tarde ou poderá enviar email para fpinhal@live.com.pt"
    }),
    email_successfully_sent_thanks: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Email successfully sent. Thanks!",
        [LANGUAGE_CODE_DEFAULT]: "Email enviado com sucesso. Obrigado!"
    }),
    during_my_career_at_INFOS_and_until_now: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "At INFOS I programmed mainly in both aspects (Frontend/Backend) in the Java and Javascript programming languages ​​with the help of the SpringBoot and React frameworks.",
        [LANGUAGE_CODE_DEFAULT]: "Na INFOS programei maioritariamente nas duas vertentes(Frontend/Backend) nas linguagens de programação Java e Javascript com o auxilio dos frameworks SpringBoot e React."
    }),
    at_Orbcom_i_program_mostly: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "At ORBCOM I program mostly in the two strands(Frontend/Backend) in the C#, Visual Basic and Javascript programming languages ​​with the auxilio of .NET Framework, .NET CORE and Angular frameworks.",
        [LANGUAGE_CODE_DEFAULT]: "Na ORBCOM programo maioritariamente nas duas vertentes(Frontend/Backend) nas linguagens de programação C#, Visual Basic e Javascript com o auxilio dos frameworks .NET Framework, .NET CORE e Angular."
    }),
    with_these_tools_i_develop_WEB_applications_clients: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "With these tools I develop WEB, API's, WEB services and maintenance and new implementations on customer projects.",
        [LANGUAGE_CODE_DEFAULT]: "Com estas ferramentas desenvolvo aplicações WEB, API's, WEB services e faço manutenção e novas implementações em projetos de clientes."
    }),
    still_on_my_current_path: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Still on my current path, although with less intensity, I program with the C# language using the .NET framework.",
        [LANGUAGE_CODE_DEFAULT]: "Embora com menos intensidade, também programo com a linguagem C# utilizando o framework .NET."
    }),
    with_these_tools_i_develop_WEB_applications: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "With these tools I develop WEB applications, microservices, APIs, WEB services and carry out maintenance and new implementations in existing projects.",
        [LANGUAGE_CODE_DEFAULT]: "Com estas ferramentas desenvolvo aplicações WEB, micro serviços, API's, WEB services e faço manutenção e novas implementações em projetos já existentes."
    }),
    regarding_databases: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Regarding databases, I have experience in management and development using SQL Server and MySQL.",
        [LANGUAGE_CODE_DEFAULT]: "Relativamente a bases de dados, tenho experiência na gestão e desenvolvimento utilizando o SQL Server e MySQL."
    }),
    decision_support_system_for_sports_betting: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Decision support system for sports betting.",
        [LANGUAGE_CODE_DEFAULT]: "Sistema de apoio à decisão para apostas desportivas."
    }),
    in_this_application_it_is_possible_to_obtain_quick_and_effective_predictions: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "In this application it is possible to obtain quick and effective predictions based on the settings chosen by the user.",
        [LANGUAGE_CODE_DEFAULT]: "Nesta aplicação é possivel obter prognósticos rápidos e eficazes com base nas definições escolhidas pelo utilizador."
    }),
    there_are_several_statistical_graphs_to_help_bettors: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "There are several statistical graphs to help bettors choose the best predictions and thus increase their profitability.",
        [LANGUAGE_CODE_DEFAULT]: "Existem vários gráficos de estatísticas para auxiliar o apostador a escolher os melhores prognósticos e aumentar assim a sua rentabilidade."
    }),
    there_is_a_connection_to_the_football_api: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "There is a connection to the football api to collect all game statistics and make them available to the user to carry out their simulations based on their settings.",
        [LANGUAGE_CODE_DEFAULT]: "Existe ligação à api football para recolher todas as estatisticas dos jogos e disponibilizar para o utilizador fazer as suas simulações com base nas suas definições."
    }),
    to_make_the_experience_more_fun_and_competitive: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "To make the experience more fun and competitive, a like system was developed, where users can evaluate the predictions generated by other bettors.",
        [LANGUAGE_CODE_DEFAULT]: "Para tornar mais divertida e competitiva a experiência, foi desenvolvido  um sistema de likes, onde os utilizadores poderão avaliar os prognósticos gerados por outros apostadores."
    }),


    platform_for_fully_dynamic_moodboard_management: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "Platform for fully dynamic moodboard management.",
        [LANGUAGE_CODE_DEFAULT]: "Plataforma para gestão de moodboards totalmente dinâmica."
    }),
    the_administrator_has_the_possibility_of_inserting_brands: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "The administrator has the possibility of inserting brands, styles, products, etc. through the platform itself.",
        [LANGUAGE_CODE_DEFAULT]: "O administrador tem a possibilidade de inserir marcas, estilos, produtos, etc através da própria plataforma."
    }),
    on_this_platform_you_can_upload_your_own_images: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "On this platform you can upload your own images, as well as use the brand's own images or by connecting to the Pinterest API to create artistic canvases.",
        [LANGUAGE_CODE_DEFAULT]: "Nesta plataforma é possivel carregar as suas próprias imagens, assim como utilizar as imagens da própria marca  ou através de conexão à api do pinterest para a elaboração de telas artisticas."
    }),
    the_fabricJs_library_was_used_to_handle_the_images: TString({
        [LANGUAGE_CODE_PT]: null,
        [LANGUAGE_CODE_EN]: "The fabricJs library was used to handle the images, making it possible to drag, invert the X and Y axis, duplicate, modify the size and change the plane of the images themselves in relation to the others.",
        [LANGUAGE_CODE_DEFAULT]: "Foi utlizada a biblioteca fabricJs para o manuseamento das imagens tornando assim possível arrastar, inverter eixo X e Y, duplicar, modificar o tamanho e alterar o plano das próprias imagens em relação às demais."
    }),
}