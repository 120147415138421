import React, { ReactNode } from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    AppBar,
    Grid,
    Toolbar as MaterialToolbar,
    Button,
    Box,
    Typography,
    Modal,
    Divider,
    Tabs,
    Tab,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    CardHeader,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Tooltip,
    Fade,
    Link,
    Paper
} from "@material-ui/core";
import DraggablePopper from '../navigation/DraggablePopper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import AccountBox from '@material-ui/icons/AccountBox';
import Footer from '../footer/Footer';
import TheBestBetLogo from '../../assets/logotbb.png';
import TrendBookLogo from '../../assets/trend-book-logo.png';
import MoodboardImage1 from '../../assets/moodboards/moodboards_1.jpg';
import MoodboardImage2 from '../../assets/moodboards/moodboards_2.jpg';
import MoodboardImage3 from '../../assets/moodboards/moodboards_3.jpg';
import MoodboardImage4 from '../../assets/moodboards/moodboards_4.jpg';
import MoodboardImage5 from '../../assets/moodboards/moodboards_5.jpg';
import TheBestBetImage1 from '../../assets/theBestBet/the_best_bet_1.jpg';
import TheBestBetImage2 from '../../assets/theBestBet/the_best_bet_2.jpg';
import TheBestBetImage3 from '../../assets/theBestBet/the_best_bet_3.jpg';
import TheBestBetImage4 from '../../assets/theBestBet/the_best_bet_4.jpg';
import TheBestBetImage5 from '../../assets/theBestBet/the_best_bet_5.jpg';
import DevIcon, {iconList} from "devicon-react-svg";
import { AppState } from '../app/state/state';
import { useDispatch, useSelector } from "react-redux";
import labels from '../../localization/labels';
import useLocalization from '../app/hooks/useLocalization';
import messages from '../../localization/messages';
import AdjustIcon from '@material-ui/icons/Adjust';
import Carousel from 'react-material-ui-carousel'



interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export interface WindowProjectsProps {
    open: boolean;
    onClose: () => void;
    onMinimize: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({

}));

const WindowProjects = (props: WindowProjectsProps) => {
    const { open, onClose, onMinimize } = props;
    useLocalization();
    const [value, setValue] = React.useState(0);
    const maximixeWindows = useSelector((state: AppState) => state.maximizeWindows);

    var itemsTheBestBet = [
        {
            name: "1"
        },
        {
            name: "2"
        },
        {
            name: "3"
        },
        {
            name: "4"
        },
        {
            name: "5"
        }
    ];

    var itemsMoodboards = [
        {
            name: "1"
        },
        {
            name: "2"
        },
        {
            name: "3"
        },
        {
            name: "4"
        },
        {
            name: "5"
        },

    ]

    const TheBestBetImages = (props) =>
    {
        return (
            <Carousel>
                {
                    itemsTheBestBet.map( (item, i) => 
                        <Grid container spacing={2} justifyContent='center'>
                            <Grid item>
                                <img src={theBestBetImages(item.name)} style={{height: 'auto', width: '100%', maxWidth: 400}}/>
                            </Grid>
                        </Grid> 
                    )
                }
            </Carousel>
        )
    }

    const MoodboardsImages = (props) =>
        {
            return (
                <Carousel>
                    {
                        itemsMoodboards.map( (item, i) => 
                            <Grid container spacing={2} justifyContent='center'>
                                <Grid item>
                                    <img src={moodboardImages(item.name)} style={{height: 'auto', width: '100%', maxWidth: 400}}/>
                                </Grid>
                            </Grid>
                        )
                    }
                </Carousel>
            )
        }

    const moodboardImages = (name: string) => {
        switch(name) {
            case '1':
                return MoodboardImage1;
            case '2':
                return MoodboardImage2;
            case '3':
                return MoodboardImage3;
            case '4':
                return MoodboardImage4;
            case '5':
                return MoodboardImage5;
            default:
                return MoodboardImage1;
        }
    }

    const theBestBetImages = (name: string) => {
        switch(name) {
            case '1':
                return TheBestBetImage1;
            case '2':
                return TheBestBetImage2;
            case '3':
                return TheBestBetImage3;
            case '4':
                return TheBestBetImage4;
            case '5':
                return TheBestBetImage5;
            default:
                return TheBestBetImage1;
        }
    }


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const WindowProjectsView = (
        <Grid container style={{ flexDirection: "column", width: maximixeWindows === "Projetos" ? "100%" : window.innerWidth / 2 }}>
            <React.Fragment>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <AccountBox />
                    <Typography variant="h6" color="inherit" noWrap>
                        {labels.projects()}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{overflowY: 'auto', height: maximixeWindows === "Projetos" ? window.innerHeight - 215 : window.innerHeight / 2, padding: 10}}>
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                    >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        style={{ borderRight: 1, borderColor: 'divider', minWidth: 200 }}
                    >
                        <Tab label="The Best Bet" {...a11yProps(0)} />
                        <Tab label="Gestor de Moodboards" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Card style={{}}>
                            <CardHeader title={"The Best Bet"}/>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Grid container justifyContent='center'>
                                        <Avatar alt="The Best Bet" src={TheBestBetLogo} style={{height: 'auto', width: '100%', maxWidth: 300}} />
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <Typography gutterBottom variant="subtitle2" component="div">
                                            <Link href="https://betproject.fabiopinhal.info" target='_blank'>
                                                {labels.see_project()}
                                            </Link> 
                                        </Typography>
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {labels.developed() + " " + labels.between() + " " + labels.july()} 2020 - {labels.november()} 2020
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid container spacing={2} style={{padding: 10}}>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Laravel"}>
                                            <div><DevIcon key={"laravel"} icon={"laravel"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"PHP"}>
                                            <div><DevIcon key={"php"} icon={"php"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"VueJs"}>
                                            <div><DevIcon key={"vuejs"} icon={"vuejs"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"CSS"}>
                                            <div><DevIcon key={"css3"} icon={"css3"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"HTML"}>
                                            <div><DevIcon key={"html5"} icon={"html5"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"MySQL"}>
                                            <div><DevIcon key={"mysql"} icon={"mysql"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Bootstrap"}>
                                            <div><DevIcon key={"bootstrap"} icon={"bootstrap"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                               
                                        </Tooltip>
                                    </Grid>
                                    <TheBestBetImages/>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ borderLeft: '0.01em solid black' }}>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={messages.decision_support_system_for_sports_betting()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.in_this_application_it_is_possible_to_obtain_quick_and_effective_predictions()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.there_are_several_statistical_graphs_to_help_bettors()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.there_is_a_connection_to_the_football_api()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.to_make_the_experience_more_fun_and_competitive()}/>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Card>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Card style={{}}>
                        <CardHeader title={"Gestor de Moodboards"}/>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Grid container justifyContent='center'>
                                        <Avatar alt="Gestor de Moodboards" src={TrendBookLogo} style={{height: 'auto', width: '100%', maxWidth: 300}} />
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <Typography gutterBottom variant="subtitle2" component="div">
                                            <Link href="https://moodboards.fabiopinhal.info" target='_blank'>
                                                {labels.see_project()}
                                            </Link> 
                                        </Typography>
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {labels.developed() + " " + labels.between() + " " + labels.december()} 2019 - {labels.april()} 2020
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid container spacing={2} style={{padding: 10}}>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Laravel"}>
                                            <div><DevIcon key={"laravel"} icon={"laravel"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"PHP"}>
                                            <div><DevIcon key={"php"} icon={"php"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"VueJs"}>
                                            <div><DevIcon key={"vuejs"} icon={"vuejs"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"CSS"}>
                                            <div><DevIcon key={"css3"} icon={"css3"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"HTML"}>
                                            <div><DevIcon key={"html5"} icon={"html5"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"MySQL"}>
                                            <div><DevIcon key={"mysql"} icon={"mysql"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>
                                        </Tooltip>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={"Bootstrap"}>
                                            <div><DevIcon key={"bootstrap"} icon={"bootstrap"} style={{width: 75, height: 75, padding: 10, fill: 'thistle', borderRadius: 20}}/></div>                               
                                        </Tooltip>
                                    </Grid>
                                    <MoodboardsImages/>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ borderLeft: '0.01em solid black',  padding: '0.5em' }}>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={messages.platform_for_fully_dynamic_moodboard_management()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.the_administrator_has_the_possibility_of_inserting_brands()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.on_this_platform_you_can_upload_your_own_images()}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={messages.the_fabricJs_library_was_used_to_handle_the_images()}/>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Card>
                    </TabPanel>
                </Box>
                <Footer/>
            </div>
            </React.Fragment>
        </Grid>
    );

    return (
        <DraggablePopper
            onMinimize={onMinimize}
            open={open}
            onClose={onClose}
            title={"Projetos"}
            children={WindowProjectsView}/>
    )
}

export default WindowProjects;
