import React, { ReactNode, useState } from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    AppBar,
    Grid,
    Toolbar as MaterialToolbar,
    Button,
    Box,
    Typography,
    Modal,
    Divider,
    IconButton,
    Tooltip,
    Fade
} from "@material-ui/core";
import DraggablePopper from '../navigation/DraggablePopper';
import AccountBox from '@material-ui/icons/AccountBox';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Background from '../../assets/bg.jpg';
import PhotoProfile from '../../assets/foto2.jpg';
import Avatar from '@material-ui/core/Avatar';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';
import GitHubIcon from '@material-ui/icons/GitHub';
import Footer from '../footer/Footer';
import { AppState } from '../app/state/state';
import { useDispatch, useSelector } from "react-redux";
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import CodeIcon from '@material-ui/icons/Code';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import labels from '../../localization/labels';
import useLocalization from '../app/hooks/useLocalization';
import messages from '../../localization/messages';

export interface WindowProfileProps {
    open: boolean;
    onClose: () => void;
    onMinimize: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
      heroUnit: {
        backgroundColor: theme.palette.background.paper,
      },
      heroContent: {
        maxWidth: 600,
        margin: '0 auto',
      },
      layout: {
        width: 'auto',
      },
      card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      cardMedia: {
        paddingTop: '56.25%', // 16:9
      },
      cardContent: {
        flexGrow: 1,
      },
      footer: {
        backgroundColor: theme.palette.background.paper,
        padding: 10
      },
}));

const WindowProfile = (props: WindowProfileProps) => {
    const { open, onClose, onMinimize } = props;
    const classes = useStyles();
    useLocalization();
    const maximixeWindows = useSelector((state: AppState) => state.maximizeWindows);
    const [onHoverHobbie, setOnHoverHobbie] = useState("");


    const WindowProfileView = (
        <Grid container style={{ flexDirection: "column", width: maximixeWindows === "Perfil" ? "100%" : window.innerWidth / 2 }}>
            <React.Fragment>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <AccountBox />
                    <Typography variant="h6" color="inherit" noWrap>
                        {labels.profile()}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{overflowY: 'auto', height: maximixeWindows === "Perfil" ? window.innerHeight - 215 : window.innerHeight / 2}}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12}>
                        <img src={Background} style={{width: '100%', height: 'auto'}}/>
                    </Grid>
                </Grid>
                <div className={classes.heroUnit}>
                    <div className={classes.heroContent}>
                        <Grid container direction='column' spacing={2} justifyContent="center" alignItems="center">
                            <Avatar alt="Remy Sharp" src={PhotoProfile} style={{ width: 200, height: 200, marginTop: -100 }} />
                            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                                Fábio Pinhal
                            </Typography>
                            <Typography variant="h6" align="center" color="textSecondary" paragraph>
                                {messages.presentation_profile()}
                            </Typography>
                        </Grid>
                        <Grid container direction='row' spacing={2} justifyContent="center" alignItems="center">
                            <Grid item
                                onMouseEnter={() => setOnHoverHobbie(labels.football())}
                                onMouseLeave={() => setOnHoverHobbie("")}>
                                <SportsSoccerIcon style={{fontSize: 60}}/>
                            </Grid>
                            <Grid item
                                onMouseEnter={() => setOnHoverHobbie(labels.program())}
                                onMouseLeave={() => setOnHoverHobbie("")}>
                                <CodeIcon style={{fontSize: 60}}/>
                            </Grid>
                            <Grid item
                                onMouseEnter={() => setOnHoverHobbie(labels.beach())}
                                onMouseLeave={() => setOnHoverHobbie("")}>
                                <BeachAccessIcon style={{fontSize: 60}}/>
                            </Grid>
                            <Grid item
                                onMouseEnter={() => setOnHoverHobbie(labels.videogames())}
                                onMouseLeave={() => setOnHoverHobbie("")}>
                                <VideogameAssetIcon style={{fontSize: 60}}/>
                            </Grid>
                            <Grid item
                                onMouseEnter={() => setOnHoverHobbie(labels.music())}
                                onMouseLeave={() => setOnHoverHobbie("")}>
                                <MusicNoteIcon style={{fontSize: 60}}/>
                            </Grid>
                        </Grid>
                        <Grid container direction='row' spacing={2} justifyContent="center" alignItems="center">
                            <Typography component="h5" variant="h5" align="center" color="primary" style={{fontWeight: "bold"}} gutterBottom>
                                {onHoverHobbie}
                            </Typography>
                        </Grid>
                    </div>
                </div>
                <Footer/>
            </div>
            </React.Fragment>
        </Grid>
    );

    return (
        <DraggablePopper
            onMinimize={onMinimize}
            open={open}
            onClose={onClose}
            title={"Perfil"}
            children={WindowProfileView}/>
    )
}

export default WindowProfile;
