import React, { ReactNode, useState, useEffect } from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    AppBar,
    Grid,
    Toolbar as MaterialToolbar,
    Button,
    Box,
    Typography,
    Modal,
    IconButton,
    Card,
    CardContent,
    CardActions,
    CardHeader,
    Divider,
    Tooltip,
    Fade,
    Fab
} from "@material-ui/core";
import SimpleDialog from '../navigation/DraggableDialog';
import DraggableDialog from '../navigation/DraggableDialog';
import DraggablePopper from '../navigation/DraggablePopper';
import JavaScriptLogo from '../../assets/knowledges/jsDesk.png';
import CSharpLogo from '../../assets/knowledges/cDesk.png';
import JavaLogo from '../../assets/knowledges/javaDesk.png';
import PHPLogo from '../../assets/knowledges/phpDesk.png';
import DevIcon, {iconList} from "devicon-react-svg";
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import AccountBox from '@material-ui/icons/AccountBox';
import Footer from '../footer/Footer';
import { AppState } from '../app/state/state';
import { useSelector } from "react-redux";
import { Alert } from '@material-ui/lab';
import useLocalization from '../app/hooks/useLocalization';
import labels from '../../localization/labels';
import messages from '../../localization/messages';

export interface WindowKnowledgeProps {
    open: boolean;
    onClose: () => void;
    onMinimize: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({

}));


const WindowKnowledge = (props: WindowKnowledgeProps) => {
    const { open, onClose, onMinimize } = props;
    useLocalization();
    const maximixeWindows = useSelector((state: AppState) => state.maximizeWindows);


    const [points, setPoints] = useState(0);
    const [ids, setIds] = useState<number[]>([]);
    const [databases, setDatabases] = useState([
        {
            id: 1,
            name: "mongodb",
            friendlyName: "MongoDB",
            value: 60
        },
        {
            id: 2,
            name: "msql_server",
            friendlyName: "SQL Server",
            value: 75
        },
        {
            id: 3,
            name: "mysql",
            friendlyName: "MySql",
            value: 75
        }
    ]);
    const [tools, setTools] = useState([
        {
            id: 4,
            name: "docker",
            friendlyName: "Docker",
            value: 30
        },
        {
            id: 5,
            name: "eclipse",
            friendlyName: "Eclipse",
            value: 75
        },
        {
            id: 6,
            name: "github",
            friendlyName: "GitHub",
            value: 60
        },
        {
            id: 7,
            name: "jira",
            friendlyName: "Jira",
            value: 80
        },
        {
            id: 8,
            name: "visualstudio",
            friendlyName: "Visual Studio",
            value: 80
        }
    ]);

    const [frameworks, setFrameworks] = useState([
        {
            id: 9,
            name: "react",
            friendlyName: "React",
            value: 90
        },
        {
            id: 10,
            name: "vuejs",
            friendlyName: "VueJs",
            value: 75
        },
        {
            id: 11,
            name: "bootstrap",
            friendlyName: "Bootstrap",
            value: 80
        },
        {
            id: 12,
            name: "laravel",
            friendlyName: "Laravel",
            value: 60
        }
    ]);

    const [languages, setLanguages] = useState([
        {
            id: 15,
            name: "php",
            friendlyName: "PHP",
            value: 70
        },
        {
            id: 16,
            name: "java",
            friendlyName: "Java",
            value: 85
        },
        {
            id: 17,
            name: "javascript",
            friendlyName: "Javascript",
            value: 75
        },
        {
            id: 18,
            name: "css3",
            friendlyName: "CSS",
            value: 70
        },
        {
            id: 19,
            name: "html5",
            friendlyName: "HTML",
            value: 80
        }
    ]);

    const handleItemClick = (item: any) => {
        if(ids.some(id => id === item.id)){
            setIds(ids.filter((id) => id !== item.id));
            setPoints(points - item.value);
        }else{
            setIds([...ids, item.id]);
            setPoints(points + item.value);
        }
    }

    const WindowKnowledgeView = (
        <Grid container style={{ flexDirection: "column", width: maximixeWindows === "Conhecimento" ? "100%" : window.innerWidth / 2 }}>
            <React.Fragment>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <AccountBox />
                    <Typography variant="h6" color="inherit" noWrap>
                        {labels.knowledge()}
                    </Typography>
                </Toolbar>
                <Card>
                    <CardContent>
                        <Alert variant="outlined" severity="info">
                            <Typography style={{fontWeight: "bold"}}>{labels.score()} - {points === 0 ? 0 : Math.round(points / ids.length)}%</Typography>
                            {messages.by_clicking_on_the_icons_it_is_possible_to_view_my_self_assessment_regarding_the_selected_items()}
                        </Alert>  
                    </CardContent>
                </Card>
            </AppBar>
            <div style={{overflowY: 'auto', height: maximixeWindows === "Conhecimento" ? window.innerHeight - 315 : window.innerHeight / 2, padding: 10}}>
                <Grid container direction='column' justifyContent="center" alignItems="center">
                    <Grid item style={{padding: 10}}>
                        <Card>
                            <CardHeader title={labels.frameworks()}/>
                            <CardContent>
                                <Grid container direction='row'>
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        title={"SpringBoot"}
                                        >
                                            <div>
                                                <svg viewBox="0 0 128 128" width={100} height={100} key={13} style={{backgroundColor: ids.some(id => id === 13) ? "#000000" : "#ffffff", padding: 10, borderRadius: 20}} onClick={() => handleItemClick({id: 13, value: 70})}>
                                                    <path d="M116.452 6.643a59.104 59.104 0 01-6.837 12.136A64.249 64.249 0 0064.205-.026C28.984-.026 0 28.982 0 64.242a64.316 64.316 0 0019.945 46.562l2.368 2.1a64.22 64.22 0 0041.358 15.122c33.487 0 61.637-26.24 64.021-59.683 1.751-16.371-3.051-37.077-11.24-61.7zM29.067 111.17a5.5 5.5 0 01-4.269 2.034c-3.018 0-5.487-2.484-5.487-5.502 0-3.017 2.485-5.501 5.487-5.501 1.25 0 2.485.433 3.452 1.234 2.351 1.9 2.718 5.384.817 7.735zm87.119-19.238c-15.843 21.122-49.68 14.003-71.376 15.02 0 0-3.852.234-7.721.867 0 0 1.45-.617 3.335-1.334 15.226-5.301 22.43-6.335 31.685-11.086 17.427-8.869 34.654-28.274 38.24-48.463-6.637 19.422-26.75 36.11-45.077 42.895-12.557 4.635-35.238 9.136-35.238 9.136l-.917-.484c-15.442-7.518-15.91-40.977 12.157-51.78 12.291-4.735 24.048-2.134 37.323-5.302 14.175-3.367 30.568-14.004 37.238-27.874 7.471 22.19 16.46 56.932.35 78.405z" fill="thistle"></path>
                                                </svg>
                                            </div>
                                    </Tooltip>
                                    
                                    {frameworks.map((x, index) => (
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={x.friendlyName}
                                        >
                                            <div>
                                                <DevIcon key={index} icon={x.name} style={{width: 100, height: 100, padding: 10, fill: 'thistle', borderRadius: 20, backgroundColor: ids.some(id => id === x.id) ? "#000000" : "#ffffff"}} onClick={() => handleItemClick(x)}/>
                                            </div>
                                        </Tooltip>
                                    ))}
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        title={"MaterialUI"}
                                        >
                                            <div>
                                                <svg viewBox="0 0 128 128" width={100} height={100} key={14} style={{backgroundColor: ids.some(id => id === 14) ? "#000000" : "#ffffff", padding: 10, borderRadius: 20}} onClick={() => handleItemClick({id: 14, value: 80})}>
                                                    <path fill="thistle" d="M.2 68.6V13.4L48 41v18.4L16.1 41v36.8L.2 68.6z"></path><path fill="thistle" d="M48 41l47.9-27.6v55.3L64 87l-16-9.2 32-18.4V41L48 59.4V41z"></path><path fill="thistle" d="M48 77.8v18.4l32 18.4V96.2L48 77.8z"></path><path fill="thistle" d="M80 114.6L127.8 87V50.2l-16 9.2v18.4L80 96.2v18.4zM111.9 41V22.6l16-9.2v18.4l-16 9.2z"></path>
                                                </svg>    
                                            </div>
                                    </Tooltip>
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        title={"Angular"}>
                                            <div>
                                                <svg viewBox="0 0 128 128" width={100} height={100} key={22} style={{backgroundColor: ids.some(id => id === 22) ? "#000000" : "#ffffff", padding: 10, borderRadius: 20}} onClick={() => handleItemClick({id: 22, value: 80})}>
                                                    <g id="surface1"> <path fill="thistle" d="M 55.296875 69.324219 L 72.703125 69.324219 L 64 48.382812 Z M 55.296875 69.324219 M 64 15.359375 L 16.332031 32.359375 L 23.601562 95.386719 L 64 117.761719 L 104.398438 95.386719 L 111.667969 32.359375 Z M 93.746094 93.492188 L 82.636719 93.492188 L 76.644531 78.539062 L 51.355469 78.539062 L 45.363281 93.492188 L 34.253906 93.492188 L 64 26.675781 Z M 93.746094 93.492188 "></path> </g>
                                                </svg>
                                            </div>
                                    </Tooltip>
                                </Grid>           
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item style={{padding: 10}}>
                        <Card>
                            <CardHeader title={labels.languages()}/>
                            <CardContent>
                                <Grid container direction='row'>
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        title={"C#"}
                                        >
                                        <svg viewBox="0 0 128 128" key={20} width={100} height={100} style={{backgroundColor: ids.some(id => id === 20) ? "#000000" : "#ffffff", padding: 10, borderRadius: 20}} onClick={() => handleItemClick({id: 20, value: 80})}>
                                            <path fill="thistle" d="M115.4 30.7L67.1 2.9c-.8-.5-1.9-.7-3.1-.7-1.2 0-2.3.3-3.1.7l-48 27.9c-1.7 1-2.9 3.5-2.9 5.4v55.7c0 1.1.2 2.4 1 3.5l106.8-62c-.6-1.2-1.5-2.1-2.4-2.7z"></path><path fill="thistle" d="M10.7 95.3c.5.8 1.2 1.5 1.9 1.9l48.2 27.9c.8.5 1.9.7 3.1.7 1.2 0 2.3-.3 3.1-.7l48-27.9c1.7-1 2.9-3.5 2.9-5.4V36.1c0-.9-.1-1.9-.6-2.8l-106.6 62z"></path><path fill="#fff" d="M85.3 76.1C81.1 83.5 73.1 88.5 64 88.5c-13.5 0-24.5-11-24.5-24.5s11-24.5 24.5-24.5c9.1 0 17.1 5 21.3 12.5l13-7.5c-6.8-11.9-19.6-20-34.3-20-21.8 0-39.5 17.7-39.5 39.5s17.7 39.5 39.5 39.5c14.6 0 27.4-8 34.2-19.8l-12.9-7.6zM97 66.2l.9-4.3h-4.2v-4.7h5.1L100 51h4.9l-1.2 6.1h3.8l1.2-6.1h4.8l-1.2 6.1h2.4v4.7h-3.3l-.9 4.3h4.2v4.7h-5.1l-1.2 6h-4.9l1.2-6h-3.8l-1.2 6h-4.8l1.2-6h-2.4v-4.7H97zm4.8 0h3.8l.9-4.3h-3.8l-.9 4.3z"></path>
                                        </svg> 
                                    </Tooltip>  
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        title={"Visual Basic"}>
                                            <div>
                                                <svg viewBox="0 0 128 128" key={23} width={100} height={100} style={{backgroundColor: ids.some(id => id === 23) ? "#000000" : "#ffffff", padding: 10, borderRadius: 20}} onClick={() => handleItemClick({id: 23, value: 70})}>
                                                    <path fill="thistle" d="M128 63.121a63.121 63.121 0 01-63.121 63.122A63.121 63.121 0 011.757 63.121 63.121 63.121 0 0164.879 0 63.121 63.121 0 01128 63.121z"></path><path d="M21.127 17.753a63.121 63.121 0 1089.12 89.12z" opacity=".1" fill="#fff"></path><path d="M61.17 38.168L47.125 77.619a21.698 21.698 0 00-1.104 4.498h-.138a23.414 23.414 0 00-1.006-4.398L31.068 38.268H24.58l18.03 49.709h6.488l18.326-49.71-6.254-.099zm13.414.1v49.709h14.44a17.043 17.043 0 0011.538-3.946 13 13 0 004.577-10.336 11.677 11.677 0 00-3.018-8.345 12.644 12.644 0 00-8.049-3.787v-.137a12.348 12.348 0 006.313-4.44 12.092 12.092 0 002.347-7.338 10.198 10.198 0 00-3.787-8.224 15.465 15.465 0 00-10.218-3.156H74.584zm12.525 5.187c6.352 0 9.528 2.414 9.528 7.24a8.028 8.028 0 01-2.742 6.508 11.322 11.322 0 01-7.477 2.367h-5.916V43.514l6.607-.059zM80.502 64.8l6.607.02c7.996 0 11.993 2.931 11.993 8.796a8.304 8.304 0 01-2.82 6.649 11.835 11.835 0 01-7.891 2.425h-7.889V64.8z" fill="#fff"></path>
                                                </svg>
                                            </div>
                                    </Tooltip>
                                    {languages.map((x, index) => (
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={x.friendlyName}
                                        >
                                            <div>
                                                <DevIcon key={index} icon={x.name} style={{width: 100, height: 100, padding: 10, fill: 'thistle', borderRadius: 20, backgroundColor: ids.some(id => id === x.id) ? "#000000" : "#ffffff"}} onClick={() => handleItemClick(x)}/>
                                            </div>
                                        </Tooltip>
                                    ))}
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        title={"TypeScript"}
                                        >
                                        <svg viewBox="0 0 128 128" key={21} width={100} height={100} style={{backgroundColor: ids.some(id => id === 21) ? "#000000" : "#ffffff", padding: 10, borderRadius: 20}} onClick={() => handleItemClick({id: 21, value: 80})}>
                                            <path fill="#fff" d="M22.67 47h99.67v73.67H22.67z"></path><path data-name="original" fill="thistle" d="M1.5 63.91v62.5h125v-125H1.5zm100.73-5a15.56 15.56 0 017.82 4.5 20.58 20.58 0 013 4c0 .16-5.4 3.81-8.69 5.85-.12.08-.6-.44-1.13-1.23a7.09 7.09 0 00-5.87-3.53c-3.79-.26-6.23 1.73-6.21 5a4.58 4.58 0 00.54 2.34c.83 1.73 2.38 2.76 7.24 4.86 8.95 3.85 12.78 6.39 15.16 10 2.66 4 3.25 10.46 1.45 15.24-2 5.2-6.9 8.73-13.83 9.9a38.32 38.32 0 01-9.52-.1 23 23 0 01-12.72-6.63c-1.15-1.27-3.39-4.58-3.25-4.82a9.34 9.34 0 011.15-.73L82 101l3.59-2.08.75 1.11a16.78 16.78 0 004.74 4.54c4 2.1 9.46 1.81 12.16-.62a5.43 5.43 0 00.69-6.92c-1-1.39-3-2.56-8.59-5-6.45-2.78-9.23-4.5-11.77-7.24a16.48 16.48 0 01-3.43-6.25 25 25 0 01-.22-8c1.33-6.23 6-10.58 12.82-11.87a31.66 31.66 0 019.49.26zm-29.34 5.24v5.12H56.66v46.23H45.15V69.26H28.88v-5a49.19 49.19 0 01.12-5.17C29.08 59 39 59 51 59h21.83z"></path>
                                        </svg>  
                                    </Tooltip>
                                </Grid>                          
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item style={{padding: 10}}>
                        <Card>
                            <CardHeader title={labels.tools()}/>
                            <CardContent>
                                <Grid container direction='row'>
                                    {tools.map((x, index) => (
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={x.friendlyName}
                                        >
                                            <div>
                                                <DevIcon key={index} icon={x.name} style={{width: 100, height: 100, padding: 10, fill: 'thistle', borderRadius: 20, backgroundColor: ids.some(id => id === x.id) ? "#000000" : "#ffffff"}} onClick={() => handleItemClick(x)} />
                                            </div>
                                        </Tooltip>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item style={{padding: 10}}>
                        <Card>
                            <CardHeader title={labels.databases()}/>
                            <CardContent>
                                <Grid container direction='row'>
                                    {databases.map((x, index) => (
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={x.friendlyName}
                                        >
                                            <div>
                                                <DevIcon key={index} icon={x.name} style={{width: 100, height: 100, padding: 10, fill: 'thistle', borderRadius: 20, backgroundColor: ids.some(id => id === x.id) ? "#000000" : "#ffffff"}} onClick={() => handleItemClick(x)} />
                                            </div>
                                        </Tooltip>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Footer/>
            </div>
            <div style={{alignSelf: "flex-end", paddingRight: 20}}>
                <Fab variant="extended" color="primary" size='large' onClick={() => {setPoints(0); setIds([]);}}>
                    {labels.reset()} {labels.score()}
                </Fab>
            </div>
            </React.Fragment>
        </Grid>
    );

    return (
        <DraggablePopper
            onMinimize={onMinimize}
            open={open}
            onClose={onClose}
            title={"Conhecimento"}
            children={WindowKnowledgeView}/>
    )
}

export default WindowKnowledge;
