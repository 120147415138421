import { AppState } from "./state";
import { SetColorThemeAction, SetDeephWindowsAction, SetLanguageAction, SetMaximizeWindowsAction, SetWindowsOpennedAction } from "./types";
import * as actionTypes from "./actionTypes";
import { combineReducers } from "redux";
import ZIndex, { emptyZIndex } from "../../../models/ZIndex";
import { LanguageCode, setCurrentLanguage } from "../../../localization/core";

const initialState: AppState = {
    maximizeWindows: "",
    colorTheme: "#606060",
    deephWindows: [],
    windowsOpenned: [],
    language: ""
}

const maximizeWindowsReducer = (state = initialState.maximizeWindows, action: SetMaximizeWindowsAction): string => {
    switch(action.type) {
        case actionTypes.SET_MAXIMIZE_WINDOWS:
            return action.maximizeWindows;
        default:
            return state;
    }
}

const deephWindowsReducer = (state = initialState.deephWindows, action: SetDeephWindowsAction): ZIndex[] => {
    switch(action.type) {
        case actionTypes.SET_DEEPH_WINDOWS:
            return [...action.deephWindows];
        default:
            return state;
    }
}

const windowsOpennedReducer = (state = initialState.windowsOpenned, action: SetWindowsOpennedAction): string[] => {
    switch(action.type) {
        case actionTypes.SET_WINDOWS_OPENNED:
            return [...action.windowsOpenned];
        default:
            return state;
    }
}

const colorThemeReducer = (state = initialState.colorTheme, action: SetColorThemeAction): string => {
    switch(action.type) {
        case actionTypes.SET_COLOR_THEME:
            return action.colorTheme;
        default:
            return state;
    }
}

const languageReducer = (state = initialState.language, action: SetLanguageAction): string => {
    switch(action.type) {
        case actionTypes.SET_LANGUAGE:
            setCurrentLanguage(action.language as LanguageCode)
            return action.language;
        default:
            return state;
    }
}

export const state = combineReducers<AppState>({
    maximizeWindows: maximizeWindowsReducer,
    deephWindows: deephWindowsReducer,
    colorTheme: colorThemeReducer,
    windowsOpenned: windowsOpennedReducer,
    language: languageReducer
});